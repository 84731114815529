const EN = {
  translation: {
    aboutUs: "About Us",
    aboutProject: "About the Project",
    trustedUs: "Trusted Us",
    ourBrands: "Brands we invest in",
    ourHistory: "History",
    voucher: "Voucher",
    contact: "Contact",
    content1par1:
      "We are a dynamic capital group that has been consistently strengthening its market position for years and implementing a clearly defined development strategy. Every year, we successfully increase our turnover and return on equity (ROE of 16.5% in 2023). Our structure includes business entities from key sectors. These include IT, cybersecurity, security, finance, training, sales, marketing, trade, logistics, production, and new media.<br /><br />Our team, with over 25 years of experience, creates innovations that set standards in IT, logistics, production, and management. We focus on maximum efficiency and use modern and innovative tools in our work. This enables us to respond quickly to the rapidly changing market needs and deliver innovative solutions. By utilizing artificial intelligence, we ensure the highest quality standards in every project, which translates into a competitive advantage for our clients.<br /><br />We offer support in obtaining EU, external, and investment funds for technology development, equipment purchase, and training programs. We are involved in numerous business, social, and charitable initiatives, for which we have received numerous awards. This motivates us to continue expanding our efforts for the community and creating innovative solutions for our clients.",
    content1par2:
      "Who are we? The true driving force of innovation. We collaborate with the best brands on the market, creating a synergistic holding. This drives dynamic technological development on a global scale.",
    content1par3:
      "Our mission is to create future technologies that expand horizons. Through continuous research and innovation, we expand our offer and increase our reach to shape the global market.",
    content1par4:
      "Join the group of partners and investors who are jointly building our vision of success. Investing in BluHolding shares gives you not only shares but also a real impact on our future successes. Become part of innovative projects that define the future of technology.",
    content1par5: "",
    boss: "Krzysztof Błaszkiewicz",
    bossTitle: "President",
    content2par1:
      "BluHolding is a capital group operating in the new technologies, financial, and media sectors.",
    content2par2: "WE OPERATE LOCALLY, REACH GLOBALLY.",
    whatIs: "What is",
    inNumbers: "Key data on the share issue",
    becomeAShareHolder: "BECOME AN INVESTOR",
    emissionProgress: "emission progress",
    emissionProgressNumber: "in progress",
    d01012023: "10.02.2023",
    startDate: "start date",
    project: "Project",
    inProgress: "in progress",
    investments: "Number of investors",
    rate: "Return on Equity (ROE for 2023)",
    successTreshold: "success threshold",
    projectDesc: "Project description",
    investPackage: "Investment packages",
    discuss: "Frequently Asked Questions (FAQ)",
    documents: "Documents",
    docPrivacyPolicy: "Privacy Policy",
    docTrademark: "Trademark Protection",
    docRZIS: "Income Statement for the Last 12 Months",
    docValuation: "BluHolding Company Valuation",
    docCashFlows: "Cash Flow Statement",
    docInformation: "Information Document",
    docAnnouncement: "Announcement",
    docRODO: "GDPR",
    docKRS: "KRS",
    docBalance: "Balance Sheet 2023",
    investList: "List of Investors",
    risks: "Risk Factors",
    infoAbout: "Information about",
    startWriting: "Start writing",
    logIn: "Log in",
    sendComment: "SEND COMMENT",
    lessThanHour: "less than an hour ago",
    years: "years ago",
    year: "a year ago",
    day: "a day ago",
    days: "days ago",
    hours: "hours ago",
    nameHidden: "Data hidden",
    more: "MORE",
    less: "LESS",
    partners: "Partners",
    ourInvestments: "Our Investments",
    invest1Title: "We Invest in People",
    invest1Content:
      "New job positions - employment growth | Certified training and courses for employees - expanding skills and qualifications | Specialized technician training | Internships and apprenticeships - potential future employees | Chill zone - work comfort leads to better results and effective task execution | Workplace modernization - new equipment, software updates | Expansion of representative network and company fleet",
    invest2Title: "New Technologies",
    invest2Content:
      "Research center - new research and training facility | Research on the use of AI in various fields | Laboratories - dedicated stations for experiments | Development of online transmission and streaming technology | Automotive - electric vehicle sector",
    invest3Title: "Security",
    invest3Content:
      "Cybersecurity - training and education increase user awareness of IT systems | Security audits - we protect the data of Polish and foreign entities | Expertise and analysis of results - continuous self-improvement and development | System monitoring and alerts",
    invest4Title: "Product and Organization Development",
    invest4Content:
      "Professional TV studio BluStreamTV | Data Center modernization - server infrastructure development | Magento hosting, resource scaling - horizontal and vertical | Data security - cloud product development | Automation and optimization of business processes - ISO standards",
    invest5Title: "We Invest in the Future",
    invest5Content:
      "Training center - modern training and conference rooms | Coworking spaces - more opportunities and business leads | Certified office equipment - occupational health and safety standards | Professional equipment for teleconferencing and online learning | Interactive online training platform",
    value: "Value",
    from: "from",
    shares: "shares",
    companyHistory: "Company History",
    epulpitShort:
      "Remote server for companies such as: accounting offices, manufacturing, trading, logistics companies and multi-departmental companies. Dedicated server for accounting software, production, invoicing, trade. Server, cloud computer.",
    epulpitLong:
      "The e-Pulpit24 service allows for remote connection to a virtual server. It's more than just cloud storage, Dropbox, or Google Drive. In other words, e-Pulpit24 is your entire computer with all programs, applications, and data, available from any device, wherever you are. The service allows multiple users, working in different locations, to use a single virtual server simultaneously. This solution is perfect for organizations where several employees use one application and database. Data security is the highest priority with e-Pulpit24. The service ensures regular backups. It continuously monitors server and device status in a dedicated Data Center. e-Pulpit24 responds immediately to any irregularities. For more demanding users, there is an additional VPN service offer.",
    blusoftShort: "IT services integrator. BluSoft holds ISO 9001:2015 and ISO/IEC 27001:2023 certifications, confirming the highest standards of quality and security.",
    blusoftLong:
      "BluSoft is an IT company with many years of experience and market reputation. We conduct security audits in companies and implement IT support. Delegate all hardware issues to BluSoft specialists. They successfully design networks and server rooms. If you are planning a new investment, BluSoft will guide you through the entire implementation process. Experts with years of experience will show you your alternatives. They will advise you on what to choose and provide the necessary equipment for work. The company has reliable servers and computers. BluSoft also offers hosting for dedicated software, CRM, ERP, or other software that requires uninterrupted work and reliable security. The company's infrastructure ensures uninterrupted and continuous operation. Daily backups of entrusted data are made. Systems are constantly monitored to prevent network threats and unauthorized access to sensitive data.",
    bluserwerShort: "Hosting, domains, data center.",
    bluserwerLong:
      "An electronic signature is a tool that enables the identification of the entities sending documents electronically. Submitting electronic forms to offices is gaining popularity among entrepreneurs. It saves a lot of time. More and more industries are opening up to this form of collaboration. An electronic signature allows participation in tenders organized by local governments and government administrations within the framework of JEDZ. It also enables the online exchange of documents with institutions such as ZUS, the Tax Office, the Customs Office, or KRS. Using an electronic signature is a significant convenience. Thanks to this service, you gain the ability to remotely sign civil-law contracts or send invoices, which is increasingly becoming a requirement. Various professional groups must adapt to the standards of conducting documentation in electronic form. This requires having a qualified signature. This applies to, among others, healthcare, judiciary, and prison services. Choose the most beneficial version of the electronic signature from the available catalog of services, and see how convenient it is!",
    blusignShort:
      "Qualified electronic signature, documents, contracts, time stamp, cryptography.",
    blusignLong:
      "An electronic signature is a tool that enables the identification of entities sending documents electronically. Submitting electronic forms to offices is gaining popularity among entrepreneurs as it saves time. More and more industries are opening up to this form of collaboration. Thanks to the electronic signature, it is possible to participate in tenders of local government and government administration (JEDZ). It also enables the online exchange of documents with institutions such as ZUS, the Tax Office, the Customs Office, KRS, etc. Using an electronic signature is a significant convenience - thanks to this service, you gain the ability to remotely sign civil-law contracts or send invoices, and this is increasingly becoming a requirement. Various professional groups must adapt to the standards of conducting documentation in electronic form, which requires having a qualified signature: healthcare, judiciary, prison services. Choose the most beneficial version of the electronic signature from our catalog of services and see how convenient it is!",
    blustreamtvShort: "TV production, live streaming, PPV platform.",
    blustreamtvLong:
      "Live broadcast producer and owner of the PPV platform. BluStreamTV offers wide possibilities for promoting a brand or event on an unlimited scale. It reaches wherever the Internet reaches. A professional team of cameramen, editors, and sound engineers ensures comprehensive transmission service. It is based on many years of experience and reliable high-quality equipment. BluStreamTV offers audiovisual transmission from various events, such as business conferences, concerts, training sessions, and webinars. It also handles broadcasts of sports events in all disciplines. BluStreamTV provides full event support from A to Z. From creating a script, through online promotion, a press conference for the media, to on-site implementation - live studio service, hosting. Additionally, it offers the use of an integrated pay-per-view platform, which allows selling tickets online.",
    blustreamacadShort: "Training for operators and journalists.",
    blustreamacadLong:
      "BluStreamTV Academy is a place that trains future camera operators, transmission producers, and video editors. An experienced team with many broadcasts to their credit shares their knowledge with course participants. It is a unique opportunity to improve your skills in practice. Students participate in real productions, events, business conferences, and sports events. BluStreamTV Academy has its own well-equipped TV studio. The brand offers the opportunity to work with professional equipment and software, as well as valuable experience in the industry.",
    adshootShort: "Media house, marketing agency, creative agency.",
    adshootLong:
      "AdShoot is a marketing agency that specializes in managing social media. It creates creative, attention-grabbing, and distinctive content on business portals and social media, such as Facebook, Instagram, LinkedIn, and YouTube. Additionally, it plans advertising campaigns, strategies, and budgets. It is a team that creates PR and marketing activities in an unconventional way. In AdShoot, you will find specialized copywriters who will create content on any topic. The company uses professional film equipment and a team of experts. It creates high-quality video recordings and multimedia materials. Trust the AdShoot brand! It will take care of your image and increase brand recognition among current and new customers. It will show that activity on social media is an effective image-building activity that brings real sales and financial results. With monthly reports, you will have full control over campaign results.",
    fundacjaShort: "Start-up, training, business space.",
    fundacjaLong:
      "The organization provides development paths for start-ups and existing companies. Its goal is to support local businesses operating in the Kuyavian-Pomeranian Voivodeship. The BluSpace Foundation helps many entrepreneurs access tools that facilitate work or accelerate the growth of companies. The team of specialists you meet at BluSpace has many years of experience in their fields. They help entrepreneurs develop their competencies and share the wealth of knowledge gained over years of work. They also enable the creation of local business support groups. Additionally, it offers access to the latest technologies. BluSpace experts work comprehensively - they support companies at every stage of development. First, they create a brand promotion strategy, then design logos and websites. They also provide tax consulting. They help acquire the first clients and build lasting business relationships with them.",
    bgsShort: "Gaming, EXPO, and New Technologies.",
    bgsLong:
      "Blu Game Show is an event where gaming, esports, and new technologies set the trends. Blu Game Show has honorary patronages from the Ministry of Sport and Tourism, the Marshal of the Kuyavian-Pomeranian Voivodeship, and the Mayor of Bydgoszcz. This shows how the event has developed in the world of gaming and esports. Blu Game Show combines entertainment for players, such as esports tournaments, with an EXPO fair that attracts top companies from the new technology industry. Participants can expect tournaments in CS2, Tekken 8, and FC 24, among others. The event is attended by top specialists from the gaming and esports sector. During dedicated discussion panels, they share behind-the-scenes secrets of this dynamic industry. There will also be a place for anime and manga fans. Blu Game Show is a great space for companies. The dedicated Business Zone is an excellent place for networking and business talks. This event is not just for video game fans. It is also a great opportunity to establish valuable business contacts. Participants will be able to exchange experiences and discover new opportunities in the gaming and new technology industry.",
    bbcShort: "Business Club, networking, business development.",
    bbcLong:
      "The Business Club brings together entrepreneurs from Bydgoszcz who mutually support the development of their businesses. The club organizes weekly meetings that help track the current situation on the local market. Entrepreneurs continuously analyze investment opportunities and improve their competencies. They engage in projects that contribute to the promotion of the city and local business. In this way, they strengthen the region's position in the Polish and international markets.",
    bofficeShort:
      "A virtual office is the most cost-effective way to run a business in a prestigious location in Bydgoszcz.",
    bofficeLong:
      "A virtual office is the most economical form of running a business in an attractive location in Bydgoszcz. The offer was created with existing companies and those planning to open their business in mind. Thanks to BluOffice, entrepreneurs can run their company in the city center without the need to rent expensive office space or sign a long-term lease. Do you need to separate your home address from your company's headquarters address? Do you want to open a new branch of your company? Use a virtual address! Blu-Office Virtual Office is located in the Bartodzieje district of Bydgoszcz. The company's headquarters in the city center raises both the prestige and credibility of your company.",
    bemShort: "Accounting, HR, payroll.",
    bemLong:
      "Blu-Em supports clients in running a business, not only from a financial and accounting perspective but also in terms of HR and payroll. It is an experienced team that continually improves its competencies in finance, accounting, and labor law. Blu-eM Accounting offers full service for entrepreneurs. It serves companies that keep accounting records, a tax revenue and expense ledger, and lump sum accounting. It provides a range of convenient online, accounting, and internet solutions. Blu-eM represents clients before state authorities. It helps with setting up a business as well as registering commercial law companies. It also handles HR and payroll. Blu-eM focuses on saving time. It only travels to the client when on-site assistance is needed. The company's IT department ensures the safe migration of data to the server. Clients gain access to their resources from any device and location worldwide.",
    bdriveShort: "<br /><br /><br />Blu Drive - Polish electric car.",
    bdriveLong:
      "The company is actively involved in the automotive industry. It implements innovative solutions and engages in pioneering projects.",
    risks1Short: "Price competition risk",
    risks1Long:
      "Many companies offer IT products, resulting in a wide range of prices for their services. BluHolding stands out with a comprehensive range of services, from audits to the implementation of modern solutions, ending with training, all at a relatively low price.",
    risks1Values:
      "Blu Holding's value lies in offering comprehensive services from audits to the implementation of modern solutions, ending with system operation training at a relatively low price.",
    risks2Short: "Increased execution cost risk",
    risks2Long:
      "We operate in Poland and Europe, which increases logistics expenses. Local competition can pose a threat in this area. BluHolding aims to develop its own processes for minimizing order costs by limiting on-site execution and completing it remotely using specialized tools.",
    risks2Values:
      "Blu Holding is working on developing its own processes for minimizing order costs by limiting on-site execution and completing it remotely using specialized tools.",
    risks3Short: "Risk of delayed returns on investment",
    risks3Long:
      "The company's operations are influenced by changing laws, global financial policies, and the development of innovative technologies. This poses a certain threat to achieving the company's goals.",
    risks3Values:
      "BluHolding focuses on optimizing decision-making processes with the help of modern monitoring tools. An analytical lab helps the company maximize expected financial results.",
    risks4Short: "Risk of delayed response to technology development",
    risks4Long:
      "The IT industry is very demanding. The pace of new technology development has accelerated significantly compared to what we observed 10 or 15 years ago. Technology from a few years ago, considered outdated, does not attract the attention of potential contractors.",
    risks4Values:
      "BluHolding's value lies in its continuously developing workforce. The company invests in training and participation in technology events, which raises the level of necessary knowledge. An additional advantage is the collaborators, who are true enthusiasts. The company operates in the field of broad-based innovation, which it actively develops.",
    risks5Short: "Company valuation risk",
    risks5Long: "",
    risks5Values:
      "Blu Holding outsources this task to an external company specializing in this field. This company pays special attention to avoiding overestimation of the value of its assets. It also takes into account inflation, asset usefulness, price diversification, and receivables and liabilities.",
    risks6Short: "Pandemic-related risk",
    risks6Long: "",
    risks6Values:
      "Blu Holding carries out tasks online. The main products are network solutions, which means using tools that eliminate direct contact with the client. These orders significantly minimize the impact of the pandemic on the company's operations.",
    risks7Short: "Risk of not obtaining the full emission amount",
    risks7Long: "",
    risks7Values:
      "Blu Holding plans investments and activities that allow obtaining the full amount. If partial funding is obtained, the goals to be achieved will only be delayed in time.",
    risks8Short: "Risk of not paying dividends",
    risks8Long: "",
    risks8Values:
      "The payment and amount of dividends depend on the company's financial results. As the company's history shows, these values have increased year by year. BluHolding predicts profits in the coming years of operation, and thus the payment of dividends will increase.",
    risks9Short: "Risk of losing the company's good reputation",
    risks9Long: "",
    risks9Values:
      "Blu Holding has implemented standard procedures for customer interaction and service as well as environmental relations. It also places great emphasis on internal management culture. Care for these aspects has been repeatedly noticed in business circles and recognized with numerous awards.",
    investPlaceholder: "Investment in a promising startup",
    giveVoucherCode: "To proceed, please enter your voucher code.",
    goNext: "Proceed",
    getVoucherBenefit: "Choose your voucher worth 1000 PLN",
    choose: "I choose",
    vpsserver: "VPS Server",
    SSLcertificate: "SSL Certificate",
    dataSpace: "Space for Your Data worth 1000 PLN",
    performanceAudit: "Performance Audit",
    article: "Sponsored article on BluStreamTV",
    banner: "Advertising banner on BluStreamTV for 1 month",
    logo: "Logo placement in the live broadcast of the planned event",
    pageNotFound: "Error 404: Page Not Found.",
    goToMain: "Return to the main page",
    history1:
      "The beginnings of BluSoft go back to when we noticed that technology was penetrating deeper into business and everyday life. We realized then that the demand for professional IT support was growing rapidly. This also applied to services related to data security and network infrastructure management. We started our mission by fully dedicating ourselves to solving the problems our clients faced. By combining expert knowledge with creative solutions, we quickly handled various challenges. We effectively solved hardware problems, software issues, and data security concerns. This earned us trust among clients, which later became the foundation of our work in the future. Over time, our skills in security auditing and IT support were increasingly appreciated. As technology evolved, we evolved with it. We improved in network design, server room creation, and IT security. Today, after years of hard work, we proudly look at our established market position. Our reputation is the result of reliability, professionalism, and a passion for technology. We continue to improve our skills to meet the growing market expectations and offer our clients comprehensive support in IT and new technologies.",
    history2:
      "For years, we have been building our position as a trusted partner in the IT industry. We start with security audits, which we conduct in companies. This way, we provide businesses with comprehensive protection. Then, we implement IT support, during which we take responsibility for the company's hardware and network. Thanks to us, you will learn about all available alternatives. We will help you choose the best solutions for your business. We will provide reliable servers and computers that will become the foundation of your brand. In addition, we also ensure the continuity of dedicated systems, such as CRM or ERP. This is all thanks to daily backups and continuous system monitoring. We comprehensively protect your data. We eliminate all threats and prevent unauthorized access. Your security is our priority, and our experience is a guarantee of peace of mind for your business.",
    history3:
      "Imagine having access to your computer from anywhere in the world, regardless of the device you use. That's exactly how e-Pulpit24 works. It's more than just cloud storage, Dropbox, or Google Drive. It's a complete workstation, with all programs, applications, and data - always at your fingertips, wherever you are. e-Pulpit24 allows multiple users located in different locations to work simultaneously on one virtual server. It's the perfect solution for companies where several people use one application and database. Data security is our highest priority. We continuously monitor the status of our servers and devices in the Data Center. We are ready to respond immediately to any irregularities. For those who require even greater security, we offer VPN services for remote desktops. With e-Pulpit24, you can be sure that your data is always protected. Work without worries, wherever you are.",
    history4:
      "Our hosting services guarantee reliability and security for your website. We ensure operational stability and professional IT support. We strive to keep your website always available online. Hosting is not just renting server space. It's a place where your website stores and processes all the necessary data, operating continuously. This allows for an immediate response in case of potential threats. We take care of updating security systems. Thanks to this, you can be sure that your data is fully protected. The BluSerwer team consists of experienced IT technicians and engineers who will help you in any situation. As a certified NASK partner, we not only register domains but also advise on their purchase. We provide full support at every stage.",
    history5:
      "With us, running a business becomes simpler and more efficient. We provide support in financial and accounting matters, as well as HR and payroll. With Blu-eM, you can fully focus on developing your business. We are an experienced team that continually improves its competencies in finance, accounting, and labor law. We provide full accounting support tailored to every form of business. We handle accounting records, tax revenue and expense ledgers, and lump-sum accounting. We offer a range of convenient online, accounting, and internet solutions. We represent you before state authorities. We support you in setting up a business and registering commercial law companies. We also handle HR and payroll. Do you need on-site assistance? We will come to your office! In most cases, we provide support remotely, giving you greater flexibility. Our IT department will take care of your company's security. It will transfer your company's resources to a remote server, accessible from any place in the world.",
    history6:
      "We specialize in live broadcasting of cultural, sports, and business events. We rely on many years of experience. We also have professional equipment for live streaming. Whether you are planning to broadcast a conference, concert, training session, webinar, or sports competition, we will certainly meet your needs. We handle everything from A to Z – from creating a script, through online promotion, organizing a press conference, to on-site event implementation. Additionally, we offer live studio services and hosting. Our integrated PPV platform allows easy online ticket sales. With BluStreamTV, your broadcasts will be of the highest quality, and their reach will extend to a wide audience online.",
    history6_1:
      "The creation of the BluStreamTV Academy is closely tied to the development and success of BluStreamTV. As the company grew, the need for qualified personnel arose. Those who could handle any demanding project and ensure the highest quality of broadcasts. The academy focuses on training future camera operators, transmission producers, video editors, as well as editors and journalists. Collaboration with BluStreamTV allows participants to gain practical knowledge directly from experienced professionals. Experts have many successful transmission projects to their credit.",
    history7:
      "Our marketing agency was born out of a true passion for creating stories in social media. We create creative, attention-grabbing, and distinctive content on social media platforms such as Facebook, Instagram, LinkedIn, and YouTube. We plan advertising campaigns, strategies, and budgets that reflect our innovative approach to PR and marketing. Every realization is the result of unconventional thinking and commitment. At the heart of our activities are also words. AdShoot copywriters are true masters of the pen! They will prepare content on any topic. They will meet even the most demanding expectations. But we don't stop there. We have professional film equipment and a team of qualified specialists. Thanks to this, we create film recordings and multimedia materials that set new standards in the industry.",
    history8:
      "The beginnings of BluBusinessClub stem from the need for cooperation and mutual support among Bydgoszcz entrepreneurs. In Bydgoszcz, a group of people with a passion for business gathered, recognizing the great value of joint action and the exchange of experiences. As entrepreneurs, we quickly understood that sharing knowledge could benefit every company. Instead of competing, we saw the potential in cooperation. We use each other's skills, ideas, and perspectives. Weekly meetings became the foundation of BluBusinessClub's activities. Thanks to them, we regularly exchange information about the local market, current trends, and investment opportunities. Meetings are an excellent opportunity to establish new business relationships and expand the network of contacts.",
    history9:
      "The organization was created with the idea of providing effective tools for startups and existing companies. The BluSpace Foundation was established with the aim of supporting entrepreneurs in the Kuyavian-Pomeranian Voivodeship. Its mission is to support business development and build an integrated business community. It is a space where entrepreneurs can share experiences and create support groups. All this with the idea of working together for the development of the local economy.",
    history10:
      "Blu Game Show was born out of our company's great passion for video games. In our team, we have many members who regularly play and actively participate in gaming culture. Blu Game Show gradually turned into a dynamic event of great entertainment and social significance. The initiative arose from the need to create a place where enthusiasts of esports, gaming, and new technologies can meet. It is also an excellent space for establishing valuable business contacts. Thanks to the dedicated business zone, entrepreneurs have their place for networking. The development of Blu Game Show and support from various institutions have made it a significant point on the gaming industry's calendar.",
    fbgroup: "Access to the closed group of investors on Facebook.",
    letterOfThanks: "A written thank you in the form of an elegant letter.",
    bgsTicket1:
      "Ticket to the Blu Game Show event for one person (valid until the end of 2024).",
    bgsTicket4:
      "Ticket to the Blu Game Show event for four people (valid until the end of 2024).",
    vipSet: "BluHolding VIP kit; mouse pad, flash drive, USB charging cable.",
    investorStatue: "Investor statue.",
    epulpitFree5:
      "Possibility to use free access to the e-Pulpit service for up to 5 workstations for 30 days.",
    bgsVip2024: "VIP zone at the Blu Game Show event in 2024.",
    productPlacement2024:
      "Company placement during the Blu Game Show event in 2024.",
    ppvAccess: "Access to the PPV platform - BluStreamTV.",
    exclusiveMeeting: "Invitation to exclusive meetings for key investors.",
    securityAudit: "Cybersecurity audit.",
    trainings:
      "Possibility to use training with funding through our intermediary.",
    recommendations: "Recommendations",
    awards: "Awards",
    csr: "CSR",
    strategicInvestor: "Strategic Investors",
    patron: "Brand Patrons",
    investor: "Investors",
    crowdfounder: "Engaged Investors",
    faq1title: "Can I buy shares for someone else, e.g., as a gift?",
    faq2title: "Does the company issue shares in paper form?",
    faq3title: "When will the additional rewards be sent?",
    faq4title: "Does the company have any debt?",
    faq5title: "Does the company own real estate?",
    faq6title: "Can I invest more than once?",
    faq7title: "What information do I need to provide if I want to invest?",
    faq8title:
      "What if I want to receive rewards at an address different from my residence?",
    faq9title: "Can I invest if I live outside Poland?",
    faq10title:
      "Do I have to pay tax after purchasing shares in this emission?",
    faq11title: "Can I sell my shares? Do I have to pay tax on sold shares?",
    faq12title:
      "Why is the emission price of shares higher than their nominal value?",
    faq13title:
      "What happens if the success threshold of the emission is not met?",
    faq14title: "Does acquiring shares require a meeting with a notary?",
    faq15title: "Are the company's shares listed on the stock exchange?",
    faq16title:
      "The Financial Supervision Authority on crowdfunding - equity financing.",
    faq17title: "How was the pre-money valuation of the company developed?",
    youDontFound: "Didn't find the answer? Ask.",
    forInvestors: "For Investors",
    forEmmiters: "For Emitters",
    docPatent: "Trademark Protection",
    docManagementSystemPolicy: "Management System Policy on the website",
    docSZBI: "Directive on the implementation of the ISMS",
    faq1content1:
      "Legal regulations do not allow purchasing shares on behalf of another person. According to applicable regulations, it is necessary to purchase shares in your own name. However, after purchasing, you can transfer them in the form of a written donation to your grandchildren, parents, spouse, or other close person.",
    faq1content2: "",
    faq2content1:
      "According to applicable law, shares are registered in an electronic shareholder register. This register ensures the security and integrity of stored data. Shareholder information appears in the register only after the new share issuance is entered into the business register.",
    faq2content2:
      "If you are interested, we will provide you with a paper share document. However, remember that such a document is purely symbolic. You do not have any rights resulting from actual shares, which are registered in the electronic shareholder register.",
    faq3content1: "",
    faq3content2: "",
    faq4content1: "",
    faq4content2: "",
    faq5content1: "",
    faq5content2: "",
    faq6content1:
      "Of course! Investments are aggregated, leading to the allocation of shares based on the total, final sum.",
    faq6content2: "",
    faq7content1:
      "You need to provide your full name and date of birth as per your ID card. It is also necessary to provide an address, but it does not have to be your residence address.",
    faq7content2: "",
    faq8content1:
      "Just indicate the address to which any rewards should be sent.",
    faq8content2: "",
    faq9content1:
      "Of course! If you are an investor from outside the country, choose SEPA Single Euro Payments Area payments in the tPay system. Then indicate your country and make the transfer according to the payment operator's instructions.",
    faq9content2: "",
    faq10content1: "No.",
    faq10content2: "",
    faq11content1:
      "Of course. You can freely trade shares. To make a purchase or sale transaction, you sign an agreement and then inform the company about the necessary changes in the shareholder register.",
    faq11content2:
      "If you make a profit from the sale (if the sale price exceeds the purchase price), you are required to pay a 19% tax on the obtained difference.",
    faq12content1:
      "The nominal value of shares is based on the company's initial capital and does not necessarily reflect its current market value. The market valuation of the company depends on various factors, including financial results, assets, liabilities, growth prospects, market environment, and the management team's competencies.",
    faq12content2: "",
    faq13content1:
      "Investors will receive a refund of the funds they have paid. Details of the refund process can be found in the regulations (you can find it in the link in the footer).",
    faq13content2: "",
    faq14content1: "No.",
    faq14content2: "",
    faq15content1:
      "The company is not listed on the stock exchange, and its shares are not available on the public market. If the company decides to debut on NewConnect or GPW in the future, the shares will be transferred to a brokerage account.",
    faq15content2: "",
    faq16content1:
      "BluHolding bases its share issuance offer on principles recommended by the Financial Supervision Authority. We encourage you to familiarize yourself with the available materials prepared by the KNF regarding crowdfunding, issuers, and investors:",
    faq16content2:
      "We encourage you to familiarize yourself with these materials. They are a valuable source of knowledge about investing, company financing, and the legal aspects of crowdfunding.",
    faq17content1:
      "To determine the company's value, two independent valuations were conducted. One was commissioned to a specialized external company. The other was conducted internally by the President of the Management Board. Both methods took into account both historical data and financial forecasts to create a comprehensive picture of the company's value.",
    faq17content2: "",
    bpsiShort:
      "A reputable company specializing in providing high-quality certified training and courses in various professional areas.",
    bpsiLong:
      "Blu Professional Skills Institute offers a wide range of training and courses. Participants can acquire knowledge in the field of IT. The program also includes operator skills training for cameramen. Additionally, the institute develops journalistic competencies and introduces marketing-related topics. Blu Professional Skills Institute was created in response to the growing demand for qualified specialists in the mentioned fields. The company consists of experienced experts and educators. They know how important it is to provide comprehensive education. Their goal is to prepare for the growing challenges of the labor market and business.",
    history_bpsi:
      "The first idea to create BPSI arose when we noticed the growing demand for specialists with unique skills. This was the result of the dynamic development of the IT industry and modern technologies. However, there is a lack of educational programs precisely tailored to the needs of these fields. In response to this gap, we created the Blu Professional Skills Institute. We focus on imparting practical skills rather than just theoretical knowledge. The inspiration was the need for qualified competencies among IT, marketing, and streaming television employees. These skills are not often available in traditional training or universities.",
    history_bluoffice:
      "The genesis of BluOffice was a response to the need to separate the home address from the company's headquarters address. Such a solution is often required in business activities. Opening a new branch of the company creates challenges related to the headquarters. A virtual address seemed like the ideal solution. BluOffice responds to the need to provide companies with flexible, economical, and prestigious solutions for business operations and headquarters. We perfectly understand the demand for virtual office services. It allows companies to focus on business. Thanks to this, BluOffice has become an innovative solution that has served companies in Bydgoszcz and the surrounding area.",
    infoSoon: "Information coming soon",
    name: "Name and surname",
    formName: "Name / first name and surname",
    formEmail: "Email address",
    formPhone: "Phone number",
    formMsg: "Message content",
    formBtn: "Send",
    menuMobileOpen: "Open menu",
    menuMobileClose: "Close menu",
    blumilShort:
      "BluHolding is the creator of CyberMil – an initiative dedicated to promoting security, a partner of the CyberMil Security Symposium 2024/2025, and the LOGIS-MIL 2024 Trade Fair.",
    blumilLong: `
    The LOGIS-MIL Fair is an excellent opportunity to present the offer addressed to the uniformed services sector. The event was primarily created with exhibitors in mind. The fair is a proven and effective way of building business relationships for them. It enables establishing contacts with decision-makers in the supply chain: experts, commanders, soldiers, and officers of uniformed units. We invite you to participate in LOGIS-MIL 2025, which is scheduled for June 2025. BluHolding supports the organization of the event by the Association of Suppliers for Uniformed Services. Additionally, it is the creator of CyberMil - the IT, cybersecurity, and new technologies division of the Fair. <br /><br /><u><a href="https://cybermil.bluholding.pl">More about LOGIS-MIL</a></u>`,
  },
};

export default EN;
