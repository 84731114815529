const PL = {
  translation: {
    aboutUs: "O nas",
    aboutProject: "Informacje o projekcie",
    trustedUs: "Zaufali nam",
    ourBrands: "Marki, w które inwestujemy",
    ourHistory: "Historia",
    voucher: "Voucher",
    contact: "Kontakt",
    content1par1:
      "Jesteśmy dynamiczną grupą kapitałową, która od lat konsekwentnie wzmacnia swoją pozycję na rynku i realizuje jasno określoną strategię rozwoju. Co roku z sukcesem zwiększamy obroty oraz stopę zwtotu z kapitału (ROE za 2023 r. na poziomie 16,5%). W skład naszych struktur wchodzą podmioty gospodarcze z kluczowych sektorów. Należą do nich IT, cyberbezpieczeństwo, bezpieczeństwo, finanse, szkolenia, sprzedaż, marketing, handel, logistyka, produkcja oraz nowe media.<br /><br />Nasz zespół, z ponad 25-letnim doświadczeniem, tworzy innowacje, które wyznaczają standardy w IT, logistyce, produkcji i zarządzaniu. Skupiamy się na maksymalnej efektywności i korzystamy z nowoczesnych i innowacyjnych narzędzi w naszej pracy. To umożliwia nam szybkie reagowanie na dynamiczne zmieniające się potrzeby rynku i dostarczanie innowacyjnych rozwiązań. Dzięki wykorzystaniu sztucznej inteligencji zapewniamy najwyższe standardy jakości w każdym projekcie, co przekłada się na przewagę konkurencyjną naszych klientów.<br /><br />Oferujemy wsparcie w pozyskiwaniu funduszy unijnych, zewnętrznych i inwestycyjnych na rozwój technologii, zakup sprzętu oraz programy szkoleniowe. Angażujemy się w liczne inicjatywy biznesowe, społeczne i charytatywne, za co otrzymaliśmy liczne wyróżnienia. To motywuje nas do dalszego rozwijania działań na rzecz społeczności i tworzenia innowacyjnych rozwiązań dla naszych klientów.",
    content1par2:
      "Kim jesteśmy? Prawdziwą siłą napędową innowacji. Współpracujemy z najlepszymi markami na rynku, tworząc synergiczny holding. Dzięki temu, napędzamy dynamiczny rozwój technologiczny na skalę globalną.",
    content1par3:
      "Naszą misją jest tworzenie technologii przyszłości, które poszerzają horyzonty. Dzięki nieustannym badaniom i innowacjom, poszerzamy naszą ofertę i zwiększamy zasięg, aby kształtować globalny rynek.",
    content1par4:
      "Dołącz do grona partnerów i inwestorów, którzy wspólnie budują naszą wizję sukcesu. Inwestycja w akcje BluHolding daje Ci nie tylko udziały, ale także realny wpływ na nasze przyszłe sukcesy. Stań się częścią innowacyjnych projektów, które definiują przyszłość technologii.",
    content1par5: "",
    boss: "Krzysztof Błaszkiewicz",
    bossTitle: "Prezes",
    content2par1:
      "BluHolding to grupa kapitałowa działająca w branży nowych technologii, finansowej oraz mediów.",
    content2par2: "DZIAŁAMY LOKALNIE DOCIERAMY GLOBALNIE.",
    whatIs: "Czym jest",
    inNumbers: "Kluczowe dane o emisji akcji",
    becomeAShareHolder: "ZOSTAŃ INWESTOREM",
    emissionProgress: "postęp emisji",
    emissionProgressNumber: "w trakcie",
    d01012023: "10.02.2023",
    startDate: "data rozpoczęcia",
    project: "Projekt",
    inProgress: "w trakcie",
    investments: "Liczba inwestorów",
    rate: "Stopa zwrotu (ROE za 2023 r.)",
    successTreshold: "próg powodzenia",
    projectDesc: "Opis projektu",
    investPackage: "Pakiety inwestorskie",
    discuss: "Najczęściej zadawane pytania (FAQ)",
    documents: "Dokumenty",
    docPrivacyPolicy: "Polityka Prywatności",
    docTrademark: "Prawo ochronne na znak towarowy",
    docRZIS: "RZIS ostatnie 12 miesięcy",
    docValuation: "Wycena spółki BluHolding",
    docCashFlows: "Rachunek Przepływów",
    docInformation: "Dokument informacyjny",
    docAnnouncement: "Ogłoszenie",
    docRODO: "RODO",
    docKRS: "KRS",
    docBalance: "Bilans 2023",
    investList: "Lista inwestorów",
    risks: "Czynniki ryzyka",
    infoAbout: "Informacje o",
    startWriting: "Zacznij pisać",
    logIn: "Zaloguj się",
    sendComment: "WYŚLIJ KOMENTARZ",
    lessThanHour: "mniej niż godzinę temu",
    years: "lat temu",
    year: "rok temu",
    day: "dzień temu",
    days: "dni temu",
    hours: "godzin temu",
    nameHidden: "Dane ukryte",
    more: "WIĘCEJ",
    less: "MNIEJ",
    partners: "Partnerzy",
    ourInvestments: "Nasze inwestycje",
    invest1Title: "Inwestujemy w ludzi",
    invest1Content:
      "Nowe stanowiska pracy - wzrost zatrudnienia | Certyfikowane szkolenia i kursy dla pracowników - poszerzanie kompetencji i kwalifikacji | Specjalistyczne szkolenia techników | Staże i praktyki zawodowe - potencjał przyszłych pracowników | Strefa chill - komfort pracy to lepsze wyniki i efektywna realizacja zadań | Modernizacja stanowisk pracy - nowy sprzęt, aktualizacje oprogramowania | Rozbudowa sieci przedstawicieli i floty firmowej",
    invest2Title: "Nowe technologie",
    invest2Content:
      "Centrum badawcze - nowy ośrodek badawczo szkoleniowy | Badania nad wykorzystaniem sztucznej inteligencji AI w różnych dziedzinach | Laboratoria - dedykowane stanowiska na potrzeby eksperymentów | Rozwój transmisji online i technologii streamu | Motoryzacja - sektor samochodów elektrycznych",
    invest3Title: "Bezpieczeństwo",
    invest3Content:
      "Cyberbezpieczeństwo - szkolenia i edukacja to wzrost samoświadomości użytkowników systemów informatycznych | Audyty bezpieczeństwa - chronimy dane polskich i zagranicznych podmiotów | Ekspertyzy i analiza wyników - ciągłe samodoskonalenie i rozwój | Monitoring i powiadomienia systemowe",
    invest4Title: "Rozwój produktów i organizacji",
    invest4Content:
      "Profesjonalne studio telewizyjne BluStremTV | Modernizacja Data Center - Rozwój infrastruktury serwerowej | Hosting Magento, skalowanie zasobów - horyzontalne i verticalne | Bezpieczeństwo danych - rozwój produktów chmury | Automatyzacja i optymalizacja procesów firmowych - standardy ISO",
    invest5Title: "Inwestujemy w przyszłość",
    invest5Content:
      " Centrum szkoleniowe - nowoczesne sale szkoleniowe i konferencyjne | Strefy coworkingowe - więcej możliwości i leadów biznesowych | Atestowane wyposażenie biurowe - standardy BHP |  Profesjonalny sprzęt dla telekonferencji i nauczania online | Interaktywna platforma szkoleniowa on-line",
    value: "Wartość",
    from: "od",
    shares: "akcji",
    companyHistory: "Historia firmy",
    epulpitShort:
      "Serwer zdalny dla firm typu: biura rachunkowe, firmy produkcyjne, handlowe, logistyczne oraz firmy wieloodziałowe. Serwer dedykowany do oprogramowania ksiegowego, produkcja, faktury, handel. Serwer, komputer w chmurze.",
    epulpitLong:
      " Usługa e-Pulpit24 pozwala na zdalne połączenie z wirtualnym serwerem. To coś więcej niż dysk w chmurze, Dropbox czy Google Drive. Innymi słowy, e-Pulpit24 to Twój cały komputer  ze wszystkimi programami, aplikacjami i danymi, dostępny z dowolnego urządzenia, gdziekolwiek jesteś. Usługa pozwala wielu użytkownikom, pracującym w różnych miejscach, na korzystanie z jednego wirtualnego serwera jednocześnie. Takie rozwiązanie doskonale sprawdza się w organizacjach, gdzie kilku pracowników korzysta z jednej aplikacji i bazy danych. Kwestia bezpieczeństwa Twoich danych  to najwyższy priorytet w przypadku e-Pulpit24. Usługa zapewnia regularne kopie bezpieczeństwa. Monitoruje stan serwerów i urządzeń w trybie ciągłym w  dedykowanym Data Center. E-Pulpit24 natychmiast reaguje na wszelkie nieprawidłowości. Dla bardziej wymagających użytkowników istnieje dodatkowa oferta w postaci usługi VPN.",
    blusoftShort:
      "Integrator usług informatycznych. Audyty cyberbezpieczeństwa, doradztwo biznesowe. BluSoft posiada certyfikaty ISO 9001:2015 i ISO/IEC 27001:2023 potwierdzające najwyższą jakość i bezpieczeństwo.",
    blusoftLong:
      "BluSoft to firma informatyczna z wieloletnim doświadczeniem i renomą na rynku. Prowadzi audyty bezpieczeństwa w przedsiębiorstwach i wdrażamy opiekę informatyczną. Przekaż wszystkie problemy ze sprzętem specjalistom BluSoft. Z powodzeniem zajmują się projektowaniem sieci i serwerowni. Jeśli planujesz nową inwestycję, BluSoft przeprowadzi Cię przez cały jej proces wdrożenia. Eksperci z wieloletnim doświadczeniem pokażą Ci, jakie masz alternatywy. Doradzą co wybrać, a także dostarczą niezbędny sprzęt do pracy. Firma posiada niezawodne serwery oraz komputery. BluSoft oferuje także hosting oprogramowania dedykowanego, CRM, ERP, bądź innego, wymagającego nieprzerwanej pracy i niezawodnych zabezpieczeń. Infrastruktura firmy zapewnia bezawaryjną i ciągłą pracę. Codziennie wykonuje kopie zapasowe powierzonych danych. Stale monitoruje systemy, aby zapobiec zagrożeniom w sieci i uniemożliwość dostęp do wrażliwych danych osobom niepowołanym.",
    bluserwerShort: "Hosting, domeny, data center.",
    bluserwerLong:
      "Podpis elektroniczny jest narzędziem, które umożliwia identyfikację tożsamości podmiotów przesyłających dokumenty drogą elektroniczną. Składanie elektronicznych formularzy do urzędów zyskuje na popularności wśród przedsiębiorców. To ogromna oszczędność czasu.. Coraz więcej branż otwiera się na taką formę współpracy. D Elektroniczny podpis pozwala na udział w przetargach organizowanych przez samorządy i administracją rządową w ramach JEDZ Umożliwia on także internetową wymianę dokumentów z instytucjami takimi jak ZUS, Urząd Skarbowy, Urząd Celny czy KRS. Korzystanie z podpisu elektronicznego to znaczne ułatwienie. Dzięki  usłudze zyskujesz możliwości zdalnego zawierania umów cywilno-prawnych, czy przesyłania faktur.  Yo coraz częściej staje się wymogiem. Poszczególne grupy zawodowe muszą się dostosować do standardów prowadzenia dokumentacji w formie elektronicznej.  Wymaga to posiadania kwalifikowanego podpisu. Dotyczy to między innymi: służby zdrowia, sądownictwa więziennictwa. Wybierz najkorzystniejszą wersję podpisu elektronicznego z  dostępnego katalogu usług, a zobaczysz jakie to wygodne!",
    blusignShort:
      "Podpis elektroniczny kwalifikowany, dokumentów, umów, znacznik czasu, kryptografia.",
    blusignLong:
      "Podpis elektroniczny jest narzędziem, które umożliwia identyfikację tożsamości podmiotów przesyłających dokumenty drogą elektroniczną. Składanie elektronicznych formularzy do urzędów zyskuje na popularności wśród przedsiębiorców, ponieważ daje możliwość zaoszczędzenia czasu. Coraz więcej branż otwiera się na taką formę współpracy. Dzięki podpisowi elektronicznemu możliwe jest stawanie do przetargów administracji samorządowej i rządowej JEDZ Umożliwia on także internetową wymianę dokumentów z instytucjami takimi jak ZUS, Urząd Skarbowy, Urząd Celny, KRS itp. Korzystanie z podpisu elektronicznego to znaczne ułatwienie - dzięki tej usłudze zyskujesz możliwości zdalnego zawierania umów cywilno-prawnych, czy przesyłania faktur, a to coraz częściej staje się wymogiem. Poszczególne grupy zawodowe, muszą się dostosować do standardów prowadzenia dokumentacji w formie elektronicznej, co wiąże się z koniecznością posiadania kwalifikowanego podpisu: służba zdrowia, sądownictwo, więziennictwo. Wybierz najkorzystniejszą wersję podpisu elektronicznego z naszego katalogu usług, a zobaczysz jakie to wygodne!",
    blustreamtvShort: "Produkcja telewizyjna, transmisja live, platforma PPV.",
    blustreamtvLong:
      "Realizator transmisji live i właściciel platformy PPV. BluStreamTV oferuje szerokie możliwości promocji marki, czy wydarzenia na nieograniczoną skalę. Dociera wszędzie tam, gdzie sięga Internet. Profesjonalny zespół operatorów kamer, montażystów oraz dźwiękowców zapewnia kompleksową obsługę transmisji. Bazuje na wieloletnim doświadczeniu i niezawodnym sprzęcie najwyższej jakości. BluStreamTV oferujeprzesył transmisji audiowizualnej z różnych wydarzeń, takich jak konferencje biznesowe, koncerty, szkolenia i webinary. Obsługuje również transmisje z zawodów sportowych we wszystkich  dyscyplinach. BluStreamTV zapewnia pełną obsługę wydarzeń od A do Z. Począwszy od stworzenia scenariusza, przez promocję w sieci, konferencję prasową dla mediów, aż po realizację na miejscu - obsługę studia live, konferansjerkę. Dodatkowo umożliwia korzystanie ze zintegrowanej platformy pay per view, która pozwala sprzedawać bilety online.",
    blustreamacadShort: "Szkolenia operatorów i dziennikarzy.",
    blustreamacadLong:
      "BluStreamTV Academy to miejsce, które szkoli przyszłych operatorów kamer, realizatorów transmisji, a także montażystów wideo. Doświadczony zespół, który ma na koncie wiele transmisji, dzieli się swoją wiedzą z uczestnikami kursu. To wyjątkowa okazja do doskonalenia swoich umiejętności w praktyce. Uczniowie biorą udział w prawdziwych realizacjach, eventach, konferencjach biznesowych oraz wydarzeniach sportowych BluStreamTV Academy posiada  własne, bogato wyposażone studio telewizyjne. Marka oferuje możliwość pracy na profesjonalnym sprzęcie i oprogramowaniu oraz wartościowego doświadczenia w branży.",
    adshootShort: "Dom mediowy, agencja marketingowa, agencja kreatywna.",
    adshootLong:
      "AdShoot to agencja marketingowa, która specjalizuje się w obsłudze mediów społecznościowych. Tworzy kreatywne, przyciągające uwagę i wyróżniające się treści na portalach biznesowych oraz w social mediach, takich jak Facebook, Instragram, LinkedIn i YouTube. Ponadto, planuje kampanie reklamowe, strategie i budżety. To zespół który w nieszablonowy sposób tworzy działania PR-owe i marketingowe. W AdShoot znajdzieszwyspecjalizowanych copywriterów, którzy stworzą treść na każdy temat. Firma korzysta z profesjonalnego sprzętu filmowego i zespołu ekspertów. worzy najwyższej jakości nagrania filmowe oraz materiały multimedialne. Zaufaj marce AdShoot!  Zadba o Twój wizerunek i podniesie rozpoznawalność marki wśród obecnych i nowych klientów. Pokaże, że aktywność w social mediach to skuteczne działania wizerunkowe, które  przynoszą realne rezultaty sprzedażowe i finansowe. Dzięki comiesięcznym raportom, będziesz mieć pełną kontrolę nad wynikami kampanii.",
    fundacjaShort: "Start-up, szkolenia, przestrzeń dla biznesu.",
    fundacjaLong:
      "Organizacja wskazuje ścieżki rozwoju dla start-upów oraz istniejących już firm. Jej celem jest wspieranie lokalnego biznesu, który działa na terenie województwa kujawsko-pomorskiego. Fundacja BluSpace pomaga wielu przedsiębiorcom sięgać po narzędzia, które ułatwiają pracę lub przyspieszają rozwój firm. Zespół specjalistów, których spotkasz w BluSpace, posiada wieloletnie doświadczenie w swoich dziedzinach. Pomaga przedsiębiorcom w rozwijaniu kompetencji oraz bogatą wiedzą zdobytą przez lata pracy. Umożliwia także budowanie grup wsparcia lokalnego biznesu. Dodatkowo, oferuje dostęp do najnowszych technologii. Eksperci BluSpace działają wszechstronnie - wspierają firmy na każdym etapie rozwoju. Najpierw, tworzą strategię promocji marki, a potem projektują logo i strony www. Zajmują się także doradztwem podatkowym. Pomagają zdobyć pierwszych klientów i budować z nimi trwałe relacje biznesowe.",
    bgsShort: "Gaming, EXPO i Nowe Technologie.",
    bgsLong:
      "Blu Game Show to wydarzenie, w którym gaming, esport i nowe technologie wyznaczają trendy..  Blu Game Show posiada honorowe patronatyMinisterstwa Sportu i Turystyki, Marszałka Województwa Kujawsko-Pomorskiego oraz Prezydenta Miasta Bydgoszczy. To świadczy o tym, jak impreza rozwinęła się w świecie gamingu i e-sportu..  Blu Game Show łączy w sobie rozrywkę dla graczy, taką jak turnieje e-sportowe, z targami EXPO, które przyciągają topowe firmy z branży nowych technologii. Na uczestników czekają między innymi turnieje CS2, Tekken 8 czy FC 24. Na wydarzenie przyjeżdżają topowi specjaliści z sektora gamingu i e-sportu. W trakcie dedykowanych paneli dyskusyjnych, dzielą się z uczestnikami zakulisowymi tajemnicami tej dynamicznej branży. Nie zabraknie również miejsca dla fanów anime czy mangi. Blu Game Show to świetna przestrzeń dla firm. Dedykowana strefa Business Zone stanowi doskonałe miejsce do networkingu i rozmów biznesowych. To wydarzenie nie jest tylko dla fanów gier komputerowych. To także świetna szansa na nawiązanie wartościowych kontaktów biznesowych. Uczestnicy będą mogli wymieniać się doświadczeniami i odkrywać nowe możliwości w branży gamingu i nowych technologii.",
    bbcShort: "Spotkania biznesowe, rynek inwestycji.",
    bbcLong:
      "Business Club zrzesza przedsiębiorców z Bydgoszczy, którzy wzajemnie wspierają rozwój własnych firm. Klub organizuje cotygodniowe spotkania, które pomagają śledzić aktualną sytuację na lokalnym rynku. Przedsiębiorcy nieustannie analizują możliwości inwestycji i podnoszą swoje kompetencje. Angażują się w projekty, które przyczyniają się do promocji miasta oraz lokalnego biznesu. Tym samym wzmacniają pozycję regionu na rynku polskim i międzynarodowym.",
    bofficeShort:
      "Wirtualne biuro to najbardziej opłacalny sposób na prowadzenie biznesu w prestiżowej  lokalizacji w Bydgoszczy.",
    bofficeLong:
      "Wirtualne biuro to najbardziej ekonomiczna forma prowadzenia działalności w atrakcyjnej lokalizacji w Bydgoszczy. Oferta powstała z myślą o istniejących firmach  oraz osobach które zamierzają otworzyć swoją działalność. Dzięki BluOffice pozwala przedsiębiorcom na prowadzenie firmy w centrum miasta bez konieczności wynajmowania kosztownej powierzchni biurowej i podpisywania długoterminowej umowy. Potrzebujesz rozdzielić adres zamieszkania od adresu siedziby przedsiębiorstwa? Chcesz otworzyć nowy oddział firmy? Skorzystaj z wirtualnego adresu! Blu-Office Wirtualne Biuro mieści się na bydgoskim osiedlu Bartodzieje. Siedziba przedsiębiorstwa w centrum miasta podnosi zarówno prestiż jak i wiarygodność Twojej firmy.",
    bemShort: "Finanse i rachunkowość, księgowość, kadry.",
    bemLong:
      "Blu-Em wspiera klientów w prowadzeniu działalności gospodarczej, nie tylko od strony finansowej i rachunkowej, ale też kadrowej i księgowej. To doświadczony zespół, który wciąż podnosi swoje kompetencje w dziedzinie finansów, rachunkowości i prawa pracy. Blu-eM Księgowość oferuje pełną obsługę dla przedsiębiorców. Obsługuje firmy, które prowadzą księgowość opartą  na księgach rachunkowych, podatkowej księdze przychodów i rozchodów oraz ryczałcie. Dostarcza szereg dogodnych rozwiązań online, księgowych i internetowych. Blu-eM reprezentuje  klientów przed organami państwowymi. Pomaga przy założeniu działalności, jak i rejestracji spółek prawa handlowego. Prowadzi również kadry i płace. Blu-eM stawia na oszczędność czasu. Dojeżdża do klienta jedynie, gdy potrzebna jest pomoc na miejscu.  Dział informatyczny firmy zapewnia bezpieczną migrację danych na serwer. Klienci uzyskają dostęp do swoich zasobów z dowolnego urządzenia i miejsca na świecie.",
    bdriveShort: "<br /><br /></br />Blu Drive - Polski samochód elektryczny.",
    bdriveLong:
      "Firma prężnie działa  w przemyśle samochodowym. Prowadzi innowacyjne rozwiązania i angażuje się w nowatorskie projekty.",

    risks1Short: "Ryzyko konkurencji cenowej",
    risks1Long:
      "Wiele firm oferuje produkty informatyczne co wpływa na szeroki wachlarz cenowy za ich usługi. BluHolding wyróżnia się kompleksową ofertą usług, od audytu po wdrożenie nowoczesnych rozwiązań, kończąc na szkoleniach, i to wszystko w relatywnie niskiej cenie.",
    risks1Values:
      "Wartością Blu Holding jest kompleksowe oferowanie usług od audytu poprzez wdrożenie nowoczesnych rozwiązań kończąc na szkoleniach obsługi systemów za relatywnie niską cenę.",

    risks2Short: "Ryzyko podwyższonych kosztów realizacji",
    risks2Long:
      "Działamy na terenie Polski i Europy, co zwiększa wydatki na logistykę. Lokalna konkurencja może stanowić w tej przestrzeni zagrożenie. BluHolding  dąży do opracowania własnych procesów minimalizacji kosztów zleceń, poprzez ograniczenie do minimum realizację na miejscu u zleceniodawcy i zakończenie jej zdalnie przy pomocy wyspecjalizowanych narzędzi.",
    risks2Values:
      "Blu Holding jest wypracowanie własnych procesów minimalizacji kosztów zleceń, poprzez ograniczenie do minimum realizację na miejscu u zleceniodawcy i zakończenie jej zdalnie przy pomocy wyspecjalizowanych narzędzi.",

    risks3Short: "Ryzyko opóźnienia oczekiwanych zysków z inwestycji",
    risks3Long:
      "Na działalność spółki wpływają zmieniające się przepisy prawa, globalna polityka finansowa czy rozwój innowacyjnych technologii. To stanowi pewne zagrożenie w  realizacji celów spółki.",
    risks3Values:
      "BluHolding stawia na optymalizację procesów decyzyjnych dzięki nowoczesnym narzędziom monitorującm. Analityczne laboratorium pomaga firmie maksymalizować oczekiwane wyniki finansowe.",

    risks4Short: "Ryzyko spóźnionej reakcji na rozwój technologii",
    risks4Long:
      "Branża IT jest bardzo wymagająca. Tempo rozwoju nowych technologii znacznie przyspieszyło w stosunku do tego, co jeszcze obserwowaliśmy 10 czy 15 lat temu. Technologia sprzed kilku lat, uznawana za przestarzałą, nie przyciąga uwagi potencjalnych kontrahentów.",
    risks4Values:
      "Wartością BluHolding jest stale rozwijająca się kadra pracowników., Firma inwestuje w  szkolenia oraz uczestnictwo w eventach technologicznych, co podnosi poziom niezbędnej wiedzy., Dodatkową zaletą są współpracownicy, którzy są prawdziwymi pasjonatami.  Firma działa w obszarze szeroko pojętych innowacji, które aktywnie rozwija.",

    risks5Short: "Ryzyko wyceny spółki",
    risks5Long: "",
    risks5Values:
      "Blu Holding zleca zewnętrznej firmie specjalizującej się w tej dziedzinie. Ta firma przykłada szczególną wagę do unikania przeszacowania wartości swoich aktywów. Uwzględnia również inflację, przydatność składników majątkowych, zróżnicowania cen oraz należności i zobowiązań.",

    risks6Short: "Ryzyko związane z pandemią",
    risks6Long: "",
    risks6Values:
      "Blu Holding realizuje zadania online. Głównymi produktami są systemy rozwiązań w sieci, a więc wykorzystywanie narzędzi eliminujących kontakt bezpośredni z klientem. Zlecenia te znacznie minimalizują wpływ pandemii na działalność spółki.",

    risks7Short: "Ryzyko nie pozyskania pełnej emisji kwoty emisji",
    risks7Long: "",
    risks7Values:
      "Blu Holding planuje inwestycje i działania które pozwalają na uzyskanie pełnej kwoty. W przypadku pozyskania części finansowania, cele do osiągnięcia, co najwyżej wydłużą się w czasie.",

    risks8Short: "Ryzyko nie wypłacenia dywidend",
    risks8Long: "",
    risks8Values:
      "Wypłata oraz wysokość dywidend uzależniona jest od wyników finansowych, które osiągnie spółka. ak wynika z historii przedsiębiorstwa, z roku na rok wspomniane wartości  wzrastały. Spółka BluHolding przewiduje zyski w kolejnych latach działalności i tym samym wypłacalność dywidend będzie wzrastać.",

    risks9Short: "Ryzyko utraty dobrego imienia marki i spółki",
    risks9Long: "",
    risks9Values:
      "Blu Holding wprowadził procedury standardów interakcji i obsługi klienta oraz relacji ze środowiskiem., Duży nacisk kładzie takżet na kulturę zarządzania wewnątrz w firmie. Troska o te aspekty była wielokrotna zauważona w kręgach biznesu i wyróżnionae licznymi nagrodami.",

    investPlaceholder: "Inwestycja w obiecujacy startup",
    giveVoucherCode: "Aby przejść dalej, należy podać kod swojego vouchera.",
    goNext: "Przejdź dalej",
    getVoucherBenefit: "Wybierz swój voucher o wartości 1000zł",
    choose: "Wybieram",
    vpsserver: "Serwer VPS",
    SSLcertificate: "Certyfikat SSL",
    dataSpace: "Przestrzeń na Twoje dane o wartości 1000zł",
    performanceAudit: "Audyt wydajności",
    article: "Artykuł sponsorowany na BluStreamTV",
    banner: "Banner reklamowy na BluStreamTV na okres 1 miesiąca",
    logo: "Lokowanie logotypu w transmisji live zaplanowanego wydarzenia",
    pageNotFound: "Błąd 404: Strona nie została odnaleziona.",
    goToMain: "Wróć do strony głównej",
    history1:
      "Początki BluSoft sięgają momentu, gdy zauważyliśmy, że technologia przenika coraz głębiej do biznesu i codziennego życia. Zrozumieliśmy wtedy, że zapotrzebowanie na profesjonalne wsparcie informatyczne szybko rośnie. Dotyczyło to także usług z zakresu bezpieczeństwa danych, jak i zarządzania infrastrukturą sieciową. Naszą misję rozpoczęliśmy od całkowitego poświęcenia się rozwiązywaniu problemów, z jakimi mierzyli się nasi klienci. Dzięki połączeniu eksperckiej wiedzy z kreatywnymi rozwiązaniami, szybko radziliśmy sobie z różnymi wyzwaniami. Skutecznie rozwiązywaliśmy problemy sprzętowe, związane z oprogramowaniem oraz bezpieczeństwem danych. To sprawiło, że zyskaliśmy zaufanie wśród klientów, które później stało się fundamentem naszej pracy w przyszłości. Z czasem nasze umiejętności w dziedzinie audytu bezpieczeństwa i opiecei informatycznej były coraz bardziej doceniane.  W miarę, gdy technologia się rozwijała, my ewoluowaliśmy wraz z nią.  Doskonaliliśmy się w projektowaniu sieci, tworzeniu serwerowni i w zabezpieczeniach informatycznych.  Dziś, po  latach wytężonej pracy, z dumą patrzymy na naszą ugruntowaną pozycję na rynku. Nasza reputacja to efekt rzetelności, profesjonalizmu i pasji do technologii. Wciąż doskonalimy nasze umiejętności, aby sprostać rosnącym oczekiwaniom rynku i oferować klientom wszechstronne wsparcie w dziedzinie IT oraz nowych technologii.",
    history2:
      "Od lat budujemy naszą pozycję jako zaufany partner w branży IT. Zaczynamy od audytów bezpieczeństwa, które przeprowadzamy w przedsiębiorstwach. W ten sposób zapewniamy biznesom kompleksową ochronę. Następnie wdrażamy opiekę informatyczną, podczas której przejmujemy odpowiedzialność za sprzęt i sieć firmy. Dzięki nam, poznasz wszystkie dostępne alternatywy. Pomożemy Ci wybrać najlepsze rozwiązania dla Twojego biznesu. Dostarczymy niezawodne serwery i komputery, które staną się fundamentem Twojej marki  Ponadto, dbamy także o ciągłość pracy dedykowanych systemów, takich jak CRM czy ERP. Wszystko dzięki codziennym kopiom zapasowym i nieustannemu monitorowaniu systemów. Kompleksowo chronimy Twoje dane. Eliminujemy wszelkie zagrożenia i zapobiegamy nieautoryzowanemu dostępowi. Twoje bezpieczeństwo to nasz priorytet, a nasze doświadczenie to gwarancja spokoju dla Twojego biznesu.",
    history3:
      "Wyobraź sobie, że masz dostęp do swojego komputera z każdego miejsca na świecie, niezależnie od urządzenia, z którego korzystasz. Tak właśnie działa e-Pulpit24. To coś więcej niż dysk w chmurze, Dropbox czy Google drive. To cała stacja robocza, z wszystkimi programami, aplikacjami i danymi - zawsze na wyciągnięcie ręki, gdziekolwiek jesteś E-pulpit24  umożliwia jednoczesną pracę wielu użytkowników znajdujących się w różnych lokalizacjach na jednym wirtualnym serwerze. To idealne rozwiązanie dla firm, w których kilka osób korzysta z jednej aplikacji i bazy danychKwestia bezpieczeństwa Twoich danych ma dla nas najwyższy priorytet. Na bieżąco monitorujemy stan naszych serwerów oraz urządzeń w Data Center. Jesteśmy gotowi do natychmiastowej reakcji na wszelkie nieprawidłowości. Dla tych, którzy wymagają jeszcze większego zabezpieczenia, oferujemy usługi VPN dla pulpitu zdalnego. Z e-Pulpit24 masz pewność, że Twoje dane są zawsze chronione. Pracuj bez obaw, gdziekolwiek się znajdujesz.",
    history4:
      "Nasze usługi hostingu to gwarancja niezawodności i bezpieczeństwa dla Twojej strony internetowej. Zapewniamy stabilność działania oraz profesjonalną opiekę informatyczną. Dążymy do tego, aby Twoja witryna była zawsze dostępna w sieci. Hosting to nie tylko wynajem przestrzeni na serwerze. To miejsce, gdzie Twoja strona internetowa przechowuje i przetwarza wszystkie potrzebne dane, działając nieprzerwanie.  To pozwala na natychmiastową reakcję w przypadku potencjalnego zagrożenia.  Dbamy o aktualizację systemów zabezpieczeń. Dzięki temu, masz pewność, że Twoje dane są w pełni chronione.  Za firmą BluSerwer stoi zespół doświadczonych techników i inżynierów IT, którzy pomogą Ci w każdej sytuacji. Jako certyfikowany partner NASK, nie tylko rejestrujemy domeny, ale także doradzamy w ich zakupie. Zapewniamy pełne wsparcie na każdym etapie.",
    history5:
      "Z nami prowadzenie działalności gospodarczej staje się prostsze i bardziej efektywne. Zapewniamy wsparcie w kwestiach finansowych i rachunkowych, jak również kadrowych i księgowych. Z Blu-eM całkowicie skupisz się na rozwoju swojego biznesu. Jesteśmy doświadczonym zespołem, który wciąż podnosi swoje kompetencje w dziedzinie finansów, rachunkowości i prawa pracy.  Zapewniamy pełne wsparcie księgowe, dostosowane do każdej formy działalności. Obsługujemy księgi rachunkowe, podatkową księgę przychodów i rozchodów, jak i ryczałt.. Dostarczamy szereg dogodnych rozwiązań online, księgowych i internetowych. Reprezentujemy Cię  przed organami państwowymi. Wspieramy przy zakładaniu działalności gospodarczej oraz rejestracji spółek prawa handlowego. Prowadzimy również kadry i płace.  Potrzebujesz pomocy na miejscu? Dojedziemy do Twojego biura! W większości przypadków zapewniamy wsparcie zdalnie, co daje Ci większą elastyczność. Nasz dział IT zadba o bezpieczeństwo Twojej firmy. Przeniesie zasoby Twojego przedsiębiorstwa na zdalny serwer, do którego masz dostęp z każdegomiejsca na świecie.",
    history6:
      "Specjalizujemy się w realizacji transmisji na żywo z wydarzeń kulturalnych, sportowych oraz biznesowych. Bazujemy na wieloletnim doświadczeniu.Posiadamy również profesjonalny sprzęt do przeprowadzania streamingu na żywo.  Niezależnie od tego, czy planujesz transmisję konferencji, koncertu, szkolenia, webinaru czy zawodów sportowych. Z pewnością sprostamy Twoim potrzebom Zajmujemy się wszystkim od A do Z – od tworzenia scenariusza, przez promocję online, organizację konferencji prasowej, aż po realizację na miejscu wydarzenia. Dodatkowo, oferujemy obsługę studia live oraz usługi konferansjerskieNasza zintegrowana platforma PPV umożliwia łatwą sprzedaż biletów online. Z BluStreamTV Twoje transmisje będą na najwyższym poziomie, a ich zasięg dotrze do szerokiej publiczności w sieci.",
    history6_1:
      "Powstanie marki BluStreamTV Academy wiąże się ściśle z rozwojem i sukcesem firmy BluStreamTV.. W miarę rozwoju firmy, pojawiła się potrzeba posiadania wykwalifikowanego personelu. Takiego, który poradzi sobie z każdym wymagającym projektem i zapewni najwyższą jakość transmisji.Akademia skupia się na szkoleniu przyszłych operatorów kamer, realizatorów transmisji oraz montażystów wideo, a także redaktorów i dziennikarzy. Współpraca z BluStreamTV umożliwia uczestnikom zdobywanie praktycznej wiedzy bezpośrednio od doświadczonych profesjonalistów. Eksperci mają na swoim koncie wiele udanych projektów transmisyjnych.",
    history7:
      "Nasza agencja marketingowa narodziła się z prawdziwej pasji do tworzenia opowieści w mediach społecznościowych. Tworzymy kreatywne, przyciągające uwagę i wyróżniające się treści na portalach społecznościowych, takich jak Facebook, Instragram, LinkedIn i YouTube. Planujemy kampanie reklamowe, strategie i budżety, które odzwierciedlają nasze innowacyjne podejście do PR-u i marketingu. Każda realizacja to efekt nieszablonowego myślenia i zaangażowania. <br /><br />W sercu naszej działalności są także słowa. Copywriterzy AdShoot to prawdziwi mistrzowie pióra! Przygotują treść na każdy temat. Sprostają nawet najbardziej wygórowanym oczekiwaniom. Na tym nie poprzestajemy. Dysponujemy profesjonalnym sprzętem filmowym oraz zespołem wykwalifikowanych specjalistów. Dzięki temu, tworzymy nagrania filmowe i materiały multimedialne, które wyznaczają nowe standardy w branży.",
    history8:
      "Początki BluBusinessClub wynikają z potrzeby współpracy i wzajemnego wsparcia bydgoskich przedsiębiorców. W Bydgoszczy zebrała się  grupa osób z pasją do biznesu, które dostrzegły   jak wielką wartość ma wspólne działanie i wymiana doświadczeń.. Jako przedsiębiorcy, szybko zrozumieliśmy, że dzielenie się wiedzą może przynieść korzyści każdej firmie. Zamiast rywalizować, dostrzegliśmy potencjał we współpracy. Korzystamy ze wzajemnych umiejętności, pomysłów i perspektyw. Cotygodniowe spotkania stały się fundamentem działalności BluBusinessClub. Dzięki nim, regularnie wymieniamy się informacjami o lokalnym rynku, aktualnych trendach i możliwościach inwestycyjnych. Spotkania są doskonałą okazją do nawiązywania nowych relacji biznesowych i powiększenia sieci kontaktów.",
    history9:
      "Organizacja powstała z myślą o dostarczaniu skutecznych narzędzi dla start-upów oraz istniejących firm.  Fundacja BluSpace powstała z myślą o wsparciu przedsiębiorców w województwie Kujawsko-Pomorskim. Jej misja to wspieranie rozwoju firm oraz budowanie zintegrowanej społeczności biznesowej. To przestrzeń, w której przedsiębiorcy mogą dzielić się doświadczeniami i tworzyć grupy wsparcia. Wszystko to z myślą o wspólnym działaniu na rzecz rozwoju lokalnej gospodarki.",
    history10:
      "Blu Game Show narodziło z ogromnej pasji do gier komputerowych w naszej firmie. W naszym zespole mamy wielu członków, którzy regularnie grają i aktywnie uczestniczą w kulturze gamingowej. Blu Game Show stopniowo zmieniało się w dynamiczne wydarzenie o dużym znaczeniu rozrywkowym i społecznościowym. Inicjatywa powstała z potrzeby stworzenia miejsca, gdzie pasjonaci e-sportu, gamingu i nowych technologii mogą się spotykać. .Jest to również doskonała przestrzeń do nawiązywania wartościowych kontaktów biznesowych. Dzięki dedykowanej strefie biznesowej, przedsiębiorcy mają swoje miejsce do networkingu. Rozwój Blu Game Show oraz wsparcie ze strony różnych instytucji uczyniły je istotnym punktem w kalendarzu branży gamingowej.",
    fbgroup: "Dostęp do zamkniętej grupy inwestorów na Facebooku.",
    letterOfThanks: "Pisemne podziękowanie w formie eleganckiego listu.",
    bgsTicket1:
      "Bilet na wydarzenie Blu Game Show dla jednej osoby (ważny do końca 2024 roku).",
    bgsTicket4:
      "Bilet na wydarzenie Blu Game Show dla czterech osób (ważny do końca 2024 roku).",
    vipSet:
      "Zestaw VIP BluHolding; podkładka pod myszkę, pendrive, kabel ładujący USB.",
    investorStatue: "Statuetka inwestora.",
    epulpitFree5:
      "Możliwość skorzystania z bezpłatnego dostępu do usługi e-Pulpit do 5 stanowisk na 30 dni.",
    bgsVip2024: "Strefa Vip na wydarzeniu Blu Game Show w 2024 roku.",
    productPlacement2024:
      "Lokowanie firmy podczas wydarzenia Blu Game Show w 2024 roku.",
    ppvAccess: "Dostęp do platformy PPV - BluStreamTV.",
    exclusiveMeeting:
      "Zaproszenie na ekskluzywne spotkania dla kluczowych inwestorów.",
    securityAudit: "Audyt cyberbezpieczeństwa.",
    trainings:
      "Możliwość skorzystania ze szkoleń z dofinansowaniem za naszym pośrednictwem.",
    recommendations: "Referencje",
    awards: "Wyróżnienia",
    csr: "CSR",
    strategicInvestor: "Inwestorzy Strategiczni",
    patron: "Mecenasi Marki",
    investor: "Inwestorzy",
    crowdfounder: "Zaangażowani Inwestorzy",

    faq1title: "Czy mogę kupić akcje dla innej osoby, np. na prezent?",
    faq2title: "Czy spółka wydaje akcje w formie papierowej?",
    faq3title: "Kiedy będą wysyłane nagrody dodatkowe?",
    faq4title: "Czy spółka posiada zadłużenie?",
    faq5title: "Czy spółka posiada nieruchomości?",
    faq6title: "Czy mogę zainwestować więcej, niż raz?",
    faq7title: "Jakie dane muszę podać, jeżeli chcę zainwestować?",
    faq8title:
      "Co jeżeli chcę otrzymać nagrody na adres inny, niż adres zamieszkania?",
    faq9title: "Czy mogę zainwestować, jeśli mieszkam poza granicami Polski?",
    faq10title: "Czy muszę zapłacić podatek po zakupie akcji w tej emisji?",
    faq11title:
      "Czy mogę sprzedać swoje akcje? Czy muszę zapłacić podatek od sprzedanych akcji?",
    faq12title:
      "Dlaczego cena emisyjna akcji jest wyższa od jej wartości nominalnej?",
    faq13title:
      "Co się stanie, jeśli nie zostanie osiągnięty próg powodzenia emisji?",
    faq14title: "Czy objęcie akcji oznacza spotkanie z notariuszem?",
    faq15title: "Czy akcje spółki są notowane na giełdzie?",
    faq16title:
      "Urząd Komisji Nadzoru Finansowego o finansowaniu społecznościowym - crowdfundingu.",
    faq17title: "Jak została opracowana wycena pre-money Spółki?",
    youDontFound: "Nie znalazłeś odpowiedzi? Zapytaj.",
    forInvestors: "Dla inwestorów",
    forEmmiters: "Dla emitentów",
    docPatent: "Prawo ochronne na znak towarowy",
    docManagementSystemPolicy: "Polityka zarządzania systemu na www",
    docSZBI: "Zarządzenie wprowadzenia SZBI",
    faq1content1:
      "Regulacje prawne nie pozwalają na dokonywanie zakupu akcji na rzecz innej osoby. Zgodnie z obowiązującymi przepisami, konieczne jest nabycie akcji na własne nazwisko. Jednak po zakupie możesz przekazać je w formie darowizny pisemnej twoim wnukom, rodzicom, małżonkowi, małżonce, dzieciom lub innej osobie bliskiej.",
    faq1content2: "",
    faq2content1:
      "Zgodnie z obowiązującym prawem, akcje są rejestrowane w elektronicznym rejestrze akcjonariuszy. Taki rejestr zapewnia bezpieczeństwo i integralność przechowywanych danych. Informacje o akcjonariuszach pojawiają się w rejestrze dopiero po wpisaniu nowej emisji akcji do rejestru przedsiębiorców.",
    faq2content2:
      "Jeśli jesteś zainteresowany, dostarczymy Ci dokument akcji w formie papierowej. Jednak pamiętaj, że taki dokument ma charakter wyłącznie symboliczny. Nie przysługują Ci żadne prawa wynikające z rzeczywistych akcji, które są zarejestrowane w elektronicznym rejestrze akcjonariuszy.",
    faq3content1: "", // TODO potrzebujemy informacji o nagrodach
    faq3content2: "",
    faq4content1: "", // TODO potrzebuje danych
    faq4content2: "",
    faq5content1: "", // TODO potrzebuje danych
    faq5content2: "",
    faq6content1:
      "Oczywiście! Inwestycje są agregowane, co prowadzi do przyznania akcji na podstawie całkowitej, ostatecznej sumy.",
    faq6content2: "",
    faq7content1:
      "Należy podać pełne imię, nazwisko oraz datę urodzenia zgodnie z danymi z dowodu osobistego. Konieczne jest także podanie adresu, jednak nie musi być to adres zamieszkania.",
    faq7content2: "",
    faq8content1:
      "Wystarczy, że wskażesz nam adres, na który mają zostać wysłane ewentualne nagrody.",
    faq8content2: "",
    faq9content1:
      "Oczywiście! Jeśli jesteś inwestorem spoza kraju, wybierz płatności przelewem SEPA Single Euro Payments Area w systemie tPay. Następnie wskaż swoje państwo i dokonaj przelewu zgodnie z instrukcjami operatora płatności.",
    faq9content2: "",
    faq10content1: "Nie. ",
    faq10content2: "",
    faq11content1:
      "Oczywiście. Akcjami możesz swobodnie handlować. W celu dokonania transakcji zakupu lub sprzedaży akcji, zawierasz umowę, a następnie informujesz spółkę o koniecznych zmianach w rejestrze akcjonariuszy.",
    faq11content2:
      "Jeśli osiągniesz zysk ze sprzedaży (jeśli cena sprzedaży przewyższa cenę zakupu) Twoim obowiązkiem jest opłacenie podatku w wysokości 19% od uzyskanej różnicy.",
    faq12content1:
      "Wartość nominalna akcji jest oparta na początkowym kapitale spółki i niekoniecznie odzwierciedla jej obecną wartość rynkową. Wycena rynkowa spółki zależy od różnych czynników, Zaliczają się do nich wyniki finansowe, aktywa i pasywa, perspektywy wzrostu, otoczenie rynkowe oraz kompetencje zespołu zarządzającego.",
    faq12content2: "",
    faq13content1:
      "Inwestorzy otrzymają zwrot środków, które wpłacili. Szczegóły dotyczące procesu zwrotu są w regulaminie (znajdziesz go pod linkiem w stopce).",
    faq13content2: "",
    faq14content1: "Nie.",
    faq14content2: "",
    faq15content1:
      "Spółka nie figuruje na giełdzie, a jej akcje nie są dostępne na rynku publicznym. Jeśli spółka zdecyduje się w przyszłości na debiut na NewConnect lub GPW, akcje zostaną przeniesione na rachunek maklerski.",
    faq15content2: "",
    faq16content1:
      "BluHolding opiera swoją ofertę emisji akcji na zasadach rekomendowanych przez Komisję Nadzoru Finansowego. Zachęcamy do zapoznania się z dostępnymi materiałami które przygotowała KNF dotyczące crowdfundingu, emitentów oraz inwestorów:", // TODO custom list element
    faq16content2:
      "Zachęcamy do zapoznania się z tymi materiałami. To cenne źródło wiedzy o inwestowaniu, finansowaniu spółek i prawnymi aspektami crowdfundingu.",
    faq17content1:
      "Dla ustalenia wartości firmy przeprowadzono dwie niezależne wyceny. Jedną z nich zlecono specjalistycznej firmie zewnętrznej. Drugą przeprowadził wewnętrznie Prezes Zarządu. Obydwie metody uwzględniły zarówno dane historyczne, jak i prognozy finansowe, do stworzenia kompleksowego obrazu wartości przedsiębiorstwa.", // TODO parafraza z Kolejkowa,u nas mają być 3 wyceny - poprawię jak zadecydujemy finalnie
    faq17content2: "",
    bpsiShort:
      "Renomowana firma specjalizująca się w dostarczaniu wysokiej jakości certyfikowanych szkoleń i kursów w różnych obszarach zawodowych.",
    bpsiLong:
      "Blu Professional Skills Institute oferuje szeroki zakres szkoleń i kursów. Uczestnicy mogą zdobyć wiedzę z zakresu IT Program obejmuje również szkolenia z umiejętności operatorskich dla kamerzystów. Dodatkowo, instytut rozwija kompetencje dziennikarskie i wprowadza w zagadnienia związane z marketingiem. Blu Professional Skills Institute powstał w odpowiedzi na rosnące zapotrzebowanie na wykwalifikowanych specjalistów w wymienionych dziedzinach.  Firmę tworzą doświadczeni eksperci i edukatorzy. Wiedzą, jak ważne jest dostarczanie kompleksowej edukacji. Ich celem jest przygotowanie na rosnące wyzwania rynku pracy i biznesu.",
    history_bpsi:
      "Pierwszy pomysł na stworzenie BPSI zrodził się, gdy zauważyliśmy rosnące zapotrzebowanie na specjalistów z unikalnymi umiejętnościami. Było to wynikiem dynamicznego rozwoju branży IT i nowoczesnych technologii.. Brakuje jednak programów edukacyjnych precyzyjnie dostosowanych do potrzeb tych dziedzin. W odpowiedzi na tę lukę stworzyliśmy Blu Professional SKills Institute. Koncentrujemy się na przekazywaniu praktycznych umiejętności, zamiast skupianiu się wyłącznie na teorii. Inspiracją była potrzeba wykwalifikowanych kompetencji wśród pracowników IT, marketingu oraz telewizji streamingowej. Umiejętności te nie są często dostępne na tradycyjnych szkoleniach czy uczelniach.",
    history_bluoffice:
      "Geneza BluOffice była odpowiedzią na potrzebę oddzielenia adresu zamieszkania od adresu siedziby firmy. Takie rozwiązanie jest często wymagane w działalności biznesowej..  Otwarcie nowego oddziału firmy stwarzaa wyzwania związane z siedzibą.  wirtualny adres wydawał się idealnym rozwiązaniem. BluOffice odpowiada na potrzebę dostarczenia firmom elastycznych, ekonomicznych i prestiżowych rozwiązań w zakresie prowadzenia działalności i siedziby. Doskonale rozumiemy popyt na usługę wirtualnego biura. Pozwala ona firmom skupić się na biznesie.Dzięki temu BluOffice stało się innowacyjnym rozwiązaniem, które służyło firmom w Bydgoszczy i okolicach.",
    infoSoon: "Informacje wkrótce",
    name: "Imie i nazwisko",
    formName: "Nazwa / imię i nazwisko",
    formEmail: "Adres e-mail",
    formPhone: "Numer telefonu",
    formMsg: "Treść wiadomości",
    formBtn: "Wyślij",
    menuMobileOpen: "Otwórz menu",
    menuMobileClose: "Zamknij menu",
    blumilShort:
      "BluHolding jest twórcą CyberMil - inicjatywy związanej z promocją bezpieczeństwa, partnerem Sympozjum Bezpieczeństwa CyberMil 2024/2025 oraz Targów LOGIS-MIL 2024.",
    blumilLong: `
    CyberMil to pion BluHolding dedykowany promocji cyberbezpieczeństwa, IT i nowych technologii. Zapraszamy do uczestnictwa w Sympozjum Bezpieczeństwa CyberMil 2025, które odbędzie się już w czerwcu w Bydgoszczy. To jedyne takie wydarzenie w województwie Kujawsko-Pomorskim w tematyce bezpieczeństwa i cyberbezpieczeństwa. Sympozjum to nie tylko prelekcje ekspertów z branży i panele dyskusyjne. To wyjątkowa okazja do networkingu, nawiązania współprac, pozyskania partnerów biznesowych czy inwestorów. <br /><br /><u><a href="https://cyber-mil.pl/">Więcej o Sympozjum</a></u>`,
    cookies: {
      header: 'Informacja o plikach cookies',
    }
  },
};

export default PL;
