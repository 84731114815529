import React from "react";
import { withTranslation } from "react-i18next";
import "./Footer.sass";
import modules from "./Cookies.module.scss";
import BluHolding from "./../Header/bluholding.webp";
import Mail from "./mail.webp";
import Phone from "./phone.webp";
import Klaster from "./bki.webp";
import Iot from "./iot.png";

class Footer extends React.Component {
  #mainPageCallback;
  #scrollInvestForm;

  constructor(props) {
    super(props);
    this.#mainPageCallback = props.mainPageCallback;
    this.#scrollInvestForm = props.scrollInvestForm;

    this.state = {
      showCookieBar: true,
    };
  }

  handleAcceptCookies = () => {
    this.setState({ showCookieBar: false });
    localStorage.setItem("cookiesAccepted", "true");
  };

  componentDidMount() {
    const cookiesAccepted = localStorage.getItem("cookiesAccepted");
    if (cookiesAccepted) {
      this.setState({ showCookieBar: false });
    }
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <footer className="Footer align-center" id="kontakt">
          <div className="grid--4-1 vgap-60 align-center">
            <div>
              <div className="w100 flex justify-center">
                <a href="/">
                  <img
                    className="Footer__img"
                    src={BluHolding}
                    alt="Blu Holding"
                  />
                </a>
              </div>
            </div>
            <div>
              <p className="Footer__text-with-icon">
                <img className="Footer__img" src={Mail} alt="Mail" />{" "}
                <a href="mailto:krzysztof.chmura@bluholding.pl">
                  krzysztof.chmura@bluholding.pl
                </a>
              </p>
              <p className="Footer__text-with-icon">
                <img className="Footer__img" src={Phone} alt="Phone" />
                <a href="tel:+48525222249">
                  (+48) 52 522 22 49&nbsp;&nbsp;&nbsp;&nbsp;
                </a>
              </p>
              <a href="https://goo.gl/maps/XaKqFyvxeWt1SF666">
                <p>ul. Kijowska 44, 85-703 Bydgoszcz</p>
              </a>
            </div>
            <div className="flex justify-center flex-direction-column align-center">
              <img className="Footer__baner" src={Iot} alt="Blu Game Show" />
              <img
                className="Footer__img"
                src={Klaster}
                alt="Bydgoski Klaster Informatyczny"
              />
            </div>
            {this.#mainPageCallback ? (
              <div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#opis-projektu")}
                    href="/#opis-projektu"
                  >
                    {t("projectDesc")}
                  </a>
                </div>
                <div>
                  <a
                    onClick={() =>
                      this.#mainPageCallback("#pakiety-inwestorskie")
                    }
                    href="/#pakiety-inwestorskie"
                  >
                    {t("investPackage")}
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#dyskusja")}
                    href="/#faq"
                  >
                    FAQ
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#dokumenty")}
                    href="/#dokumenty"
                  >
                    {t("documents")}
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#lista-inwestorow")}
                    href="/#lista-inwestorow"
                  >
                    {t("investList")}
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#czynniki-ryzyka")}
                    href="/#czynniki-ryzyka"
                  >
                    {t("risks")}
                  </a>
                </div>
                <div>
                  <a
                    onClick={() => this.#mainPageCallback("#nasze-inwestycje")}
                    href="/#nasze-inwestycje"
                  >
                    {t("ourInvestments")}
                  </a>
                </div>
                <div>
                  <a
                    href="/cookies"
                  >
                    Polityka cookies
                  </a>
                </div>
              </div>
            ) : (
              <div>
                <div>
                  <a href="/#opis-projektu">{t("projectDesc")}</a>
                </div>
                <div>
                  <a href="/#pakiety-inwestorskie">{t("investPackage")}</a>
                </div>
                <div>
                  <a href="/#faq">FAQ</a>
                </div>
                <div>
                  <a href="/#dokumenty">{t("documents")}</a>
                </div>
                <div>
                  <a href="/#lista-inwestorow">{t("investList")}</a>
                </div>
                <div>
                  <a href="/#czynniki-ryzyka">{t("risks")}</a>
                </div>
                <div>
                  <a href="/#nasze-inwestycje">{t("ourInvestments")}</a>
                </div>
                <div>
                  <a href="/cookies">Polityka cookies</a>
                </div>
              </div>
            )}
          </div>
        </footer>

        <a
          className="mobileContact"
          href="/#investorForm"
          onClick={() =>
            window.location.href.split("/")[3] === "#investorForm" ||
            window.location.href.split("/")[3] === ""
              ? this.#scrollInvestForm(true)
              : (window.location.href = "/#investorForm")
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="20"
            height="20"
            fill="currentColor"
            className="bi bi-envelope-fill"
            viewBox="0 0 16 16"
          >
            <path d="M.05 3.555A2 2 0 0 1 2 2h12a2 2 0 0 1 1.95 1.555L8 8.414.05 3.555ZM0 4.697v7.104l5.803-3.558L0 4.697ZM6.761 8.83l-6.57 4.027A2 2 0 0 0 2 14h12a2 2 0 0 0 1.808-1.144l-6.57-4.027L8 9.586l-1.239-.757Zm3.436-.586L16 11.801V4.697l-5.803 3.546Z" />
          </svg>
        </a>

        {this.state.showCookieBar && (
          <div className={modules.CookieBar}>
            <p className={modules.CookieBar__text}>
              Ta strona korzysta z plików cookies w celu poprawy wygody
              użytkowania. Klikając przycisk „Akceptuję”, wyrażasz zgodę na
              wykorzystywanie plików cookies.{" "}
              <a href="/cookies" target="_blank" rel="noopener noreferrer">
                Dowiedz się więcej
              </a>
            </p>
            <button
              className={modules.CookieBar__button}
              onClick={this.handleAcceptCookies}
            >
              Akceptuję
            </button>
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(Footer);
