import React from "react";
import { useTranslation } from "react-i18next";
import Header from "../../components/Header/Header";
import Footer from "../../components/Footer/Footer";
import modules from "./Cookies.module.scss";

const Cookies = () => {
  const { t } = useTranslation();

  return (
    <div>
      <Header />
      <div className={modules.cookiesPage}>
        <h1>{t("cookies.header")}</h1>
        <div className={modules.content}>
          <div className={modules.heading}>
            POLITYKA COOKIES
            <br />
            www.bluholding.pl
            <br />
            Obowiązuje od 10.02.2025
            <br />
            §1
            <br />
            Postanowienia ogólne
          </div>

          <div className={modules.paragraph}>
            <ol>
              <li>
                Właścicielem Strony i Administratorami danych osobowych
                podawanych w trakcie korzystania ze Strony prowadzonego pod
                adresem www.bluholding.pl jest grupa BluHolding w skład
                kt&oacute;rej wchodzą następujące firmy:
              </li>
            </ol>
            <p>
              <strong>BLUHOLDING Prosta Sp&oacute;łka Akcyjna</strong>, ul.
              Kijowska 44, 85-703 Bydgoszcz, wpisana do Rejestru
              Przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego
              przez Sąd Rejonowy w Bydgoszczy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS: 1018668 , REGON:
              524449295, NIP: PL5543010583
            </p>
            <p>oraz</p>
            <p>
              <strong>BluStreamTV Sp. z o.o,</strong> ul. Kijowska 44, 85-703
              Bydgoszcz, wpisana do Rejestru Przedsiębiorc&oacute;w Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Bydgoszczy,
              XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem
              KRS: 872582, REGON: 387649255, NIP: PL5542989441
            </p>
            <p>oraz</p>
            <p>
              <strong>BluStreamTV Academy Sp. z o.o.</strong> ul. Kijowska 44,
              85-703 Bydgoszcz, wpisana do Rejestru Przedsiębiorc&oacute;w
              Krajowego Rejestru Sądowego prowadzonego przez Sąd Rejonowy w
              Bydgoszczy, XIII Wydział Gospodarczy Krajowego Rejestru Sądowego,
              pod numerem KRS: 943033, REGON: 520805505, NIP: PL5542999190
            </p>
            <p>oraz</p>
            <p>
              <strong>BLUSOFT Sp. z o.o.</strong> ul. Kijowska 44, 85-73
              Bydgoszcz, wpisana do Rejestru Przedsiębiorc&oacute;w Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Bydgoszczy,
              XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem
              KRS: 578823, REGON: 362652179, NIP: 5542932079.&nbsp;
            </p>
            <p>oraz</p>
            <p>
              <strong>Blu Professional Skills Institute Sp. z o.o.</strong> ul.
              Kijowska 44, 85-73 Bydgoszcz, wpisana do Rejestru
              Przedsiębiorc&oacute;w Krajowego Rejestru Sądowego prowadzonego
              przez Sąd Rejonowy w Bydgoszczy, XIII Wydział Gospodarczy
              Krajowego Rejestru Sądowego, pod numerem KRS: 1051983, REGON:
              526069929, NIP: 5543015675.
            </p>
            <p>oraz</p>
            <p>
              <strong>Fundacja BLUSPACE </strong>ul. Kijowska 42, 85-703
              Bydgoszcz, wpisana do Rejestru Przedsiębiorc&oacute;w Krajowego
              Rejestru Sądowego prowadzonego przez Sąd Rejonowy w Bydgoszczy,
              XIII Wydział Gospodarczy Krajowego Rejestru Sądowego, pod numerem
              KRS: 943555, REGON: 520844528, NIP: 5542999362&nbsp;
            </p>
            <p>oraz</p>
            <p>
              <strong>Blu IT Team Krzysztof Błaszkiewicz</strong>, ul. Kijowska
              44, 85-703 Bydgoszcz, NIP 5541699770 REGON 341590150
            </p>
            <p>
            </p>
            <ol>
              <li>
                Strona używa plik&oacute;w cookies, aby dostarczać usługi i
                funkcje dostosowane do preferencji i potrzeb Użytkownik&oacute;w
                strony, w szczeg&oacute;lności pliki cookies pozwalają rozpoznać
                urządzenie Użytkownika i odpowiednio wyświetlić stronę
                internetową, dostosowaną do jego indywidualnych potrzeb.
              </li>
            </ol>
          </div>
          <div className={modules.heading}>
            §2
            <br />
            Stosowane definicje
          </div>
          <div className={modules.paragraph}>
            <ol>
              <li>
                <strong>Cookies</strong> - małe pliki, kt&oacute;re są pobierane
                i przechowywane na komputerze lub innym urządzeniu Użytkownika,
                powiązanym z informacjami o sposobie użytkowania strony.
              </li>
              <li>
                <strong>Użytkownik</strong> - osoba, kt&oacute;ra dobrowolnie
                korzysta z usług i treści dostępnych w Serwisie i Sklepie, tj.
                przegląda strony Serwisu i Sklepu, składa zam&oacute;wienie w
                Sklepie, rejestruje się, zapisuje się na bezpłatny newsletter
                lub kontaktuje się z właścicielem Sklepu poprzez formularz
                kontaktowy.
              </li>
              <li>
                <strong>Serwis</strong> (zamiennie Strona) - serwis dostępny pod
                adresem www.bluholding.pl,&nbsp;za pomocą kt&oacute;rego
                użytkownik może przeglądać zawartość post&oacute;w blogowych,
                zapisać się na newsletter lub skontaktować się z Administratorem
                danych osobowych.
              </li>
            </ol>
            <p>
              <strong>Dane osobowe</strong> - wszelkie informacje, kt&oacute;re
              bez nadmiernego czasu i kosztu mogą doprowadzić do identyfikacji
              osoby fizycznej, w tym jej dane identyfikacyjne, adresowe i
              kontaktowe.
            </p>
          </div>
          <div className={modules.heading}>
            §3
            <br />
            Cele cookies
          </div>
          <div className={modules.paragraph}>
            <ol>
              <li>
                Pliki cookies mogą mieć charakter tymczasowy, tj. są usuwane z
                chwilą zamknięcia przeglądarki, lub trwały.&nbsp;
              </li>
              <li>
                Stałe pliki cookies są przechowywane także po zakończeniu
                korzystania ze stron Serwisu i służą do przechowywania
                informacji takich jak hasło czy login, co przyspiesza i ułatwia
                korzystanie ze stron, a także umożliwia zapamiętanie wybranych
                przez Użytkownika ustawień.
              </li>
              <li>
                Serwis wykorzystuje pliki cookies w następujących celach:&nbsp;
              </li>
              <ol>
                <li>
                  Dostosowania zawartości stron Serwisu do indywidualnych
                  preferencji Użytkownika oraz optymalizacji korzystania ze
                  stron internetowych (wyświetlanie dostosowane do potrzeb -
                  cookies funkcjonalne),
                </li>
                <li>
                  Dokonania analiz i statystyk, kt&oacute;re dostarczą
                  informacji, w jaki spos&oacute;b Użytkownik Serwisu korzysta
                  ze stron, co umożliwia ulepszanie ich struktury i zawartości w
                  tym pixel Meta i Google Analytics 4
                </li>
                <li>
                  Marketingowych i/lub remarketingowych, w tym kierowania
                  dedykowanych treści, w szczeg&oacute;lności do
                  Użytkownik&oacute;w, kt&oacute;rzy odwiedzili stronę
                  internetową lub Fanpage Administratora danych osobowych na
                  portalu społecznościowym Facebook i/lub Instagram.
                </li>
              </ol>
              <li>
                Dane zawarte w cookies będą archiwizowane i wykorzystywane w
                celach analizy statystycznej i oceny globalnego ruchu
                użytkownik&oacute;w Serwisu.
              </li>
              <li>
                Dane zawarte w cookies nie są łączone z przekazanymi przez
                Użytkownika danymi osobowymi podczas zawarcia umowy lub kontaktu
                z Administratorem danych.
              </li>
            </ol>
          </div>
          <div className={modules.heading}>
            §4
            <br />
            Narzędzia związane z gromadzeniem cookies lub udostępnianiem
            cookies.
          </div>
          <div className={modules.paragraph}>
            <p>
              W celu analizy ruchu na stronie Serwisu, źr&oacute;dła ruchu, jak
              i optymalizacji działań marketingowych zainstalowano na
              stronie:&nbsp;
            </p>
            <ol>
              <li>
                Google Analytics- narzędzie dostarczane przez Google LLC. 1600
                Amphitheatre Parkway Mountain View, CA 94043, USA, co pozwala na
                analizę ruchu Użytkownika na stronie Serwisu i jego podstronach
                (kraj/miasto, liczba odwiedzin, przeglądane strony, czas
                odwiedzin, etc.). Gł&oacute;wny plik cookie używany przez Google
                Analytics, &bdquo;_ga", umożliwia usłudze rozr&oacute;żnianie
                poszczeg&oacute;lnych Użytkownik&oacute;w i&nbsp;działa przez
                2&nbsp;lata. Każdy plik &bdquo;_ga&rdquo; jest unikalny dla
                konkretnej usługi, więc nie można go użyć do śledzenia
                użytkownika lub przeglądarki w&nbsp;innych, niepowiązanych
                witrynach. Ustawienia prywatności w usługach Google mogą zostać
                zmienione przez Użytkownika, więcej informacji na: www.
                <a href="https://policies.google.com/privacy">
                  policies.google.com/privacy
                </a>{" "}
                W celu stałego zablokowania cookies gromadzonych przez Google
                Analytics, Użytkownik może skorzystać z dodatku do przeglądarki
                opracowanego i dostarczanego przez Google LLC na stronie: www.
                <a href="https://tools.google.com/dlpage/gaoptout">
                  tools.google.com/dlpage/gaoptout
                </a>
              </li>
              <li>
                Piksel Facebooka - narzędzie dostarczane przez Meta Platforms
                Ireland Limited (dawniej: Facebook Inc,), adres: 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Dublin, D02X525,
                Irlandia,&nbsp;kt&oacute;re umożliwia pomiar, optymalizację i
                tworzenie niestandardowych grup odbiorc&oacute;w, pozwala na
                zbieranie informacji na temat korzystania przez Użytkownika ze
                strony Serwisu i kierowanie do niego dedykowanych treści w
                serwisie społecznościowym Facebook. W celu kontroli ustawień
                prywatności w tym serwisie społecznościowym Użytkownik może
                dokonać w dowolnym momencie zmiany swoich preferencji i
                ustawień. Więcej informacji na:{" "}
                <a href="https://www.facebook.com/privacy/">
                  www.facebook.com/privacy/
                </a>
                . Piksel Facebooka pozwala na tworzenie niestandardowych grup
                odbiorc&oacute;w i/lub podobnych grup odbiorc&oacute;w do
                os&oacute;b, kt&oacute;re wchodziły już w interakcję z Serwisem
                lub materiałami Administratora danych udostępnionymi na
                portalach społecznościowych Facebook i/lub Instagram,
                prowadzonych przez Administratora danych, przy czym retencja
                danych dostarczanych przez piksel Facebooka w
                szczeg&oacute;lności wynosi odpowiednio:
              </li>
              <ol>
                <li>
                  witryna internetowa (Serwis) - maksymalnie 180 dni od
                  interakcji z witryną,
                </li>
                <li>
                  fanpage na Facebooku - maksymalnie 365 dni od interakcji z
                  kontem,
                </li>
                <li>
                  film udostępniony na fanpage&rsquo; u Administratora na
                  Facebooku - maksymalnie 365 dni od interakcji z materiałem,
                </li>
                <li>
                  wydarzenia organizowane lub wsp&oacute;łorganizowane przez
                  Administratora na fanpage&rsquo;u na Facebooku - maksymalnie
                  365 dni od interakcji z materiałem.
                </li>
              </ol>
              <li>
                Messenger Facebooka - narzędzie dostarczane przez Meta Platforms
                Ireland Limited (dawniej: Facebook Inc.), adres: 4 Grand Canal
                Square, Grand Canal Harbour, Dublin 2, Dublin, D02X525,
                Irlandia,&nbsp;kt&oacute;re umożliwia kontaktowanie się
                Użytkownika z właścicielem Serwisu poprzez Messenger w serwisie
                społecznościowym Facebook/Instagram. Korzystanie z czatu
                Facebooka na stronie Serwisu jest dobrowolne. Użytkownik może
                prowadzić rozmowę jako gość lub zalogować się na swoje konto w
                tym serwisie. Więcej informacji na:{" "}
                <a href="https://www.facebook.com/privacy/">
                  www.facebook.com/privacy/
                </a>
              </li>
              <li>
                Wtyczkę/Widget Facebooka/Instagrama - narzędzie umożliwiające
                bezpośrednie przejście Użytkownika na Fanpage Administratora
                danych, prowadzony na stronie portalu społecznościowego
                Facebook/Instagram, prowadzonego na portalu dostarczanym przez
                Meta Platforms Ireland Limited (dawniej: Facebook Inc.), adres:
                4 Grand Canal Square, Grand Canal Harbour, Dublin 2, Dublin,
                D02X525, Irlandia. Po przejściu do serwisu społecznościowego
                Facebook i Instagram Użytkownicy podlegają dodatkowo zasadom
                ochrony prywatności, opisanym w tym serwisie, w
                szczeg&oacute;lności na stronie:{" "}
                <a href="https://www.facebook.com/privacy/">
                  www.facebook.com/privacy/
                </a>
              </li>
              <li>
                Wtyczkę/Widget YouTube - umożliwia przejście Użytkownika do
                kanału YouTube - prowadzonego przez Administratora danych w tym
                serwisie. Narzędzie dostarczane przez Google LLC. 1600
                Amphitheatre Parkway Mountain View, CA 94043, USA,
                kt&oacute;rego Użytkownicy podlegają dodatkowo zasadom ochrony
                prywatności, opisanym w tym serwisie, w szczeg&oacute;lności na
                stronie: www.
                <a href="https://policies.google.com/privacy">
                  policies.google.com/privacy
                </a>
              </li>
              <li>
                Wtyczkę/Widget TIK TOK - umożliwia przejście Użytkownika do
                kanału TIK TOK prowadzonego przez Administratora danych w tym
                serwisie społecznościowym. Narzędzie dostarczane przez Bejing
                ByteDance Technology Co Ltd., Room 503 5F Building 2 43 North
                Third Ring West Road Beijing, 100086 Chiny, kt&oacute;rego
                Użytkownicy podlegają dodatkowo zasadom ochrony prywatności,
                opisanym w tym serwisie, w szczeg&oacute;lności na stronie:{" "}
                <a href="https://www.tiktok.com/legal/tiktok-website-cookies-policy">
                  www.tiktok.com/legal/tiktok-website-cookies-policy
                </a>
              </li>
              <li>
                Przetwarzanie danych zawartych w cookies przez dostawc&oacute;w,
                kt&oacute;rych siedziby i/lub serwery są zlokalizowane w
                państwach trzecich będzie się wiązać z przekazaniem danych
                zawartych w cookies poza Europejski Obszar Gospodarczy (EOG).
                Przekazanie danych zawartych w cookies, w szczeg&oacute;lności
                adres IP użytkownika odbywa się na podstawie decyzji Komisji
                Europejskiej 2021/914 w&nbsp;sprawie standardowych klauzul
                umownych dotyczących przekazywania danych osobowych do państw
                trzecich zawartych w ramach regulamin&oacute;w usług realizowany
                pomiędzy Administratorem danych, a dostawcą rozwiązania.
              </li>
            </ol>
          </div>
          <div className={modules.heading}>
            §5
            <br />
            Akceptacja lub zmiana ustawień cookies.
          </div>
          <div className={modules.paragraph}>
            <ol>
              <li>
                Użytkownik po wejściu na stronę Sklepu/Serwisu może zaakceptować
                stosowanie cookies w powyższych celach, odrzucić je lub wyrazić
                zgodę tylko na wybrane pliki cookies.
              </li>
              <li>
                Użytkownik może w każdej chwili także skasować i/lub wyłączyć
                cookies w przeglądarce internetowej, z kt&oacute;rej korzysta
                podczas przeglądania Serwisu/Sklepu.
              </li>
              <li>
                Skasowanie lub wyłączenie plik&oacute;w cookies może spowodować
                trudności lub ograniczoną funkcjonalność niekt&oacute;rych
                części Serwisu (dotyczy cookies funkcjonalnych). Niekt&oacute;re
                pliki cookies mogą być bowiem niezbędne do prawidłowego
                działania stron Serwisu i wyświetlania treści na danym rodzaju
                urządzenia.
              </li>
              <li>
                W celu zarządzania cookies Użytkownik może skorzystać także z
                funkcji dostępnych w przeglądarce, z kt&oacute;rej aktualnie
                korzysta. Instrukcje zarządzania cookies dostępne są na stronach
                dostawc&oacute;w przeglądarek internetowych, w
                szczeg&oacute;lności:
              </li>
            </ol>
            <p>
              <a href="https://support.microsoft.com/pl-pl/help/10607/microsoft-edge-view-delete-browser-history">
                Microsoft Edge
              </a>
            </p>
            <p>
              <a href="https://support.microsoft.com/pl-pl/help/278835/how-to-delete-cookie-files-in-internet-explorer">
                Microsoft Internet Explorer
              </a>
            </p>
            <p>
              <a href="http://support.google.com/chrome/bin/answer.py?hl=pl&amp;answer=95647">
                Google Chrome
              </a>
            </p>
            <p>
              <a href="https://support.mozilla.org/pl/products/firefox/protect-your-privacy/cookies">
                Mozilla Firefox
              </a>
            </p>
            <p>
              <a href="http://support.apple.com/kb/PH5042">Safari (macOS)</a>
            </p>
            <p>
              <a href="http://help.opera.com/Windows/12.10/pl/cookies.html">
                Opera
              </a>
            </p>
            <p>
              <a href="https://support.brave.com/hc/en-us/articles/360048833872-How-Do-I-Clear-Cookies-And-Site-Data-In-Brave-">
                Brave
              </a>
            </p>
          </div>
          <div className={modules.heading}>
            §6
            <br />
            Postanowienia końcowe
          </div>
          <div className={modules.paragraph}>
            W przypadku zmiany obowiązującej Polityki cookies, w szczególności w
            sytuacji, gdy będą tego wymagały zastosowane rozwiązania techniczne
            lub zmiany przepisów prawa w zakresie prywatności Użytkowników,
            wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu,
            które będą obowiązywać po 14 dniach od dnia ich publikacji na
            stronach Serwisu.
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
};

export default Cookies;
